import {
    Button, Center,
    Container,
    Flex,
    Grid,
    Input,
    Loader,
    Text,
    Title,
} from "@mantine/core";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useGetCustomerDetails} from "../utils/api/customers/useGetCustomerDetails";
import {AddressCard} from "./AddressCard";
import {useForm} from "@mantine/form";
import {useUpdateCustomer} from "../utils/api/customers/useUpdateCustomer";
import {useDisclosure, useMediaQuery} from "@mantine/hooks";
import {
    ICustomerDetailsResponse,
    ICustomerUpdateInput,
} from "../utils/api/types";
import {
    addressFormInitialValues,
    userDetailsFormInitialValues,
    userDetailsFormValidations,
} from "./utils";
import {AddressModal} from "./AddressModal";
import {Trans, useTranslation} from "react-i18next";
import {notifications} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons";
import _, {uniqueId} from "lodash";
import {FinishedOrderCardV2} from "./FInishedOrderCardV2";

export const MyAccountContent: FunctionComponent<{
    activeTab: "info" | "addresses" | "orders";
}> = ({activeTab}) => {
    const accessToken: string | null = localStorage.getItem ( "accessToken" );
    const [inputsDisabled, setInputsDisabled] = useState ( true );
    const [openedNewAddressModal, {open: openNewAddressModal, close: closeNewAddressModal}] = useDisclosure ( false );
    const {t} = useTranslation ()
    const isMobile = useMediaQuery ( "(max-width: 30em)" );

    const userDetailsForm = useForm ( {
        initialValues: userDetailsFormInitialValues,
        validate: userDetailsFormValidations ( t ),
    } );

    const succesCallBack = (data: ICustomerDetailsResponse) => {
        userDetailsForm.setValues ( {
            email: data?.customer.email || "",
            phone: data?.customer.phone || "",
            firstName: data?.customer.firstName || "",
            lastName: data?.customer.lastName || "",
        } );
    };

    const {refetch, data, isLoading} = useGetCustomerDetails (
        accessToken || "",
        succesCallBack
    );

    const onInfoEdit = () => {
        setInputsDisabled ( (prevState) => !prevState );
    };


    const onEditAccountDetailsSuccess = (data: any) => {
        if (data?.customerUpdate.customerUserErrors.length > 0) {
            notifications.show ( {
                icon: <IconX size='1.1rem'/>,
                title: t ( 'notification.error' ),
                color: 'red',
                message: data?.customerUpdate.customerUserErrors[0].message
            } )
        } else {
            setInputsDisabled ( true )
            notifications.show ( {
                icon: <IconCheck size='1.1rem'/>,
                title: t ( 'notification.success' ),
                color: 'green',
                message: '',
            } )
        }
    }

    const {mutate, isLoading: isEditLoading} = useUpdateCustomer ( onEditAccountDetailsSuccess );

    const onSubmitForm = (values: any) => {
        const customerNewValues: ICustomerUpdateInput = {
            customer: {
                email: values.email,
                phone: values.phone,
                firstName: values.firstName,
                lastName: values.lastName,
            },
        };
        mutate ( customerNewValues );
    };

    useEffect ( () => {
        if (inputsDisabled) {
            refetch ();
        }
    }, [inputsDisabled] );


    if (isLoading || isEditLoading) {
        return <Center>
            <Loader color='gray'/>
        </Center>;
    }

    if (activeTab === "info") {
        return (
            <>
                <Container px={0} mt={isMobile ? 'xl' : 0}>
                    <form
                        onSubmit={userDetailsForm.onSubmit ( (values) => onSubmitForm ( values ) )}
                    >
                        <Grid ml={isMobile ? 0 : 'md'}>
                            <Grid.Col xs={12}>
                                <Title order={4}
                                       align={isMobile ? 'center' : 'left'}>{t ( 'myAccount.accountInformation' )}</Title>
                                <Text align={isMobile ? 'center' : 'left'}>
                                    {t ( 'myAccount.accountInformation.details' )}
                                </Text>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Input.Wrapper label={t ( "form.email" )} error={userDetailsForm.errors.email}>
                                    <Input
                                        disabled={inputsDisabled}
                                        placeholder="you@slwactive.com"
                                        radius="md"
                                        {...userDetailsForm.getInputProps ( "email" )}
                                    />
                                </Input.Wrapper>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Input.Wrapper
                                    label={t ( "form.phoneNumber" )}
                                    error={userDetailsForm.errors.phone}
                                >
                                    <Input
                                        disabled={inputsDisabled}
                                        placeholder="+40745123123"
                                        radius="md"
                                        {...userDetailsForm.getInputProps ( "phone" )}
                                    />
                                </Input.Wrapper>
                            </Grid.Col>

                            <Grid.Col xs={6}>
                                <Input.Wrapper
                                    label={t ( "form.firstName" )}
                                    error={userDetailsForm.errors.firstName}
                                >
                                    <Input
                                        disabled={inputsDisabled}
                                        placeholder="John"
                                        radius="md"
                                        {...userDetailsForm.getInputProps ( "firstName" )}
                                    />
                                </Input.Wrapper>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Input.Wrapper
                                    label={t ( "form.lastName" )}
                                    error={userDetailsForm.errors.lastName}
                                >
                                    <Input
                                        disabled={inputsDisabled}
                                        placeholder="Doe"
                                        radius="md"
                                        {...userDetailsForm.getInputProps ( "lastName" )}
                                    />
                                </Input.Wrapper>
                            </Grid.Col>

                            <Grid.Col xs={6}>
                                <Text fz="sm">{t ( "myAccount.finishedOrders" )}</Text>
                                <Text fz="sm" fw={700}>
                                    {data?.customer.numberOfOrders}
                                </Text>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Text fz="sm">{t ( "myAccount.memberFrom" )}</Text>
                                <Text fz="sm" fw={700}>
                                    {new Date ( data?.customer.createdAt || "" ).toLocaleDateString ()}
                                </Text>
                            </Grid.Col>
                        </Grid>
                        <Flex m="xl" justify={"center"}>
                            {inputsDisabled ? (
                                <Button size="sm" radius="xl" onClick={() => onInfoEdit ()}>
                                    {t ( 'edit' )}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        size="sm"
                                        radius="xl"
                                        variant="outline"
                                        mx="md"
                                        onClick={() => onInfoEdit ()}
                                    >
                                        {t ( 'cancel' )}
                                    </Button>
                                    <Button size="sm" radius="xl" type={"submit"}>
                                        {t ( 'save' )}
                                    </Button>
                                </>
                            )}
                        </Flex>
                    </form>
                </Container>
            </>
        );
    }

    if (activeTab === "addresses") {
        return (
            <>
                <Container px={0} mt={isMobile ? 'xl' : 0}>
                    <Grid ml={isMobile ? 0 : 'md'}>
                        <Grid.Col xs={12} mb="md">
                            <Title order={4}
                                   align={isMobile ? 'center' : 'left'}>{t ( "myAccount.myAddresses" )}</Title>
                            <Text align={isMobile ? 'center' : 'left'}>{ 
                                <Trans
                                    i18nKey="myAccount.myAddresses.details"
                                    components={[<br />, <Text color="brand" fw={700} />]}
                                />}</Text>
                        </Grid.Col>
                        {data?.customer.addresses.nodes.map ( (address, index) =>
                            <Grid.Col md={6} xs={12} key={_.uniqueId ()}>
                                <AddressCard refetchData={refetch} address={address} index={index}/>
                            </Grid.Col> )}
                        <Grid.Col xs={12}>
                            <Flex m="xl" justify={"center"}>
                                <Button size="sm" radius="xl" onClick={openNewAddressModal}>
                                    {t ( 'myAccount.myAddresses.add' )}
                                </Button>
                            </Flex>
                        </Grid.Col>
                    </Grid>
                </Container>
                <AddressModal refetchCustomerData={refetch} initialValues={addressFormInitialValues} isEditMode={false}
                              opened={openedNewAddressModal}
                              close={closeNewAddressModal}/>
            </>
        );
    }

    if (activeTab === "orders") {
        return (
            <>
                <Container px={0} mt={isMobile ? 'xl' : 0}>
                    <Grid ml={isMobile ? 0 : 'md'}>
                        <Grid.Col xs={12} mb="md">
                            <Title order={4} align={isMobile ? 'center' : 'left'}>{t("myAccount.myOrders")}</Title>
                            <Text align={isMobile ? 'center' : 'left'}>{t("myAccount.myOrders.details")}</Text>
                        </Grid.Col>
                        {data?.customer.orders.nodes.map ( order =>
                            <Grid.Col md={6} xs={12} key={uniqueId ()}>
                                <FinishedOrderCardV2 order={order} key={uniqueId ()}/>
                            </Grid.Col> )}
                    </Grid>
                </Container>
            </>
        );
    }

    return null;
};
