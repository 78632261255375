import React, {FunctionComponent, useEffect} from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Homepage from "./Homepage/Homepage";
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {translations} from "./utils/i18n/i18n";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {LoginPage} from "./LoginPage/LoginPage";
import {RegisterPage} from "./RegisterPage/RegisterPage";
import ProductsWithFiltersPage from "./ProductsWithFiltersPage/ProductsWithFiltersPage";
import {MyAccountPage} from "./MyAccountPage/MyAccountPage";
import ProductPage from "./ProductPage/ProductPage";
import {AboutUs} from "./AboutUs/AboutUs";
import {Faq} from "./Faq/Faq";
import {ContactSection} from "./ContactSection/ContactSection";
import {ErrorPage} from "./ErrorPage/ErrorPage";
import { Articles } from "./Articles/Articles";

const App: FunctionComponent = () => {
        i18n
            .use ( detector )
            .use ( initReactI18next )
            .init ( {
                resources: translations,
                fallbackLng: "en",
                keySeparator: false,
                interpolation: {
                    escapeValue: false,
                },
            } );
        const queryClient = new QueryClient ();
        const checkIfTokenIsValid = () => {
            const accessToken: string | null = localStorage.getItem ( 'accessToken' );
            const expiresAt: string | null = localStorage.getItem ( 'expiresAt' );
            if (accessToken !== null && expiresAt && expiresAt > new Date ().toISOString ()) {
                localStorage.setItem ( 'isLogged', 'true' );
            } else {
                localStorage.setItem ( 'isLogged', 'false' );
            }
        };

        useEffect ( () => {
            checkIfTokenIsValid ();
        }, [] )

        const router = createBrowserRouter( [
            {
                path: "/",
                element: <Homepage/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: "/login",
                element: <LoginPage/>,
            },
            {
                path: "/register",
                element: <RegisterPage/>,
            },
            {
                path: '/products',
                element: <ProductsWithFiltersPage/>
            },
            {
                path: '/product/:productId',
                element: <ProductPage/>
            },
            {
                path: '/myaccount',
                element: <MyAccountPage/>
            },
            {
                path: '/aboutus',
                element: <AboutUs/>
            },
            {
                path: '/faq',
                element: <Faq/>
            },
            {
                path: '/contact',
                element: <ContactSection/>
            },
            {
                path: '/articles',
                element: <Articles/>
            },
        ]
)
;


return (
    <MantineProvider theme={{
        colors: {
            brand: ['#9DA6BD', '#939CB6', '#8993AF', '#7E8AA9', '#7481A2', '#6A779B', '#626F92', '#5B6788', '#54607E', '#4E5873']
        },
        primaryColor: 'brand',
    }}>
        <QueryClientProvider client={queryClient}>
            <Notifications/>
            <RouterProvider router={router}/>
        </QueryClientProvider>
    </MantineProvider>
);
}
;

export default App;
