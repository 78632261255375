import React, {FunctionComponent} from "react";
import {Grid, Input, Modal, Button, Flex, Text, Select} from "@mantine/core";
import {AddressInput, ICreateAddressInput} from "../utils/api/types";
import {useCreateCustomerAddress} from "../utils/api/customers/useCreateCustomerAddress";
import {useForm} from "@mantine/form";
import {addressFormValidations} from "./utils";
import {judete} from "../utils/resources/judete";
import {notifications} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {useUpdateCustomerAddress} from "../utils/api/customers/useUpdateCustomerAddress";
import {useStyles} from "../utils/generalStyles";
import {useMediaQuery} from "@mantine/hooks";

export const AddressModal: FunctionComponent<{
  opened: boolean;
  close: () => void;
  isEditMode: boolean,
  initialValues: AddressInput,
  refetchCustomerData: () => void
}> = ({ opened, close, isEditMode, initialValues, refetchCustomerData }) => {
  const {t} = useTranslation()
  const {classes} = useStyles();
  const isMobile = useMediaQuery("(max-width: 30em)");
  const addressForm = useForm({
    initialValues: initialValues,
    validate: addressFormValidations(t)
  });

  const successCallBack = (data: any) => {
    if(data && (data.customerAddressCreate?.customerUserErrors.length || data.customerAddressUpdate?.customerUserErrors.length )) {
      notifications.show({
        icon: <IconX size='1.1rem' />,
        title: t('notification.error'),
        color: 'red',
        message: data.customerAddressCreate?.customerUserErrors[0].message || data.customerAddressUpdate?.customerUserErrors[0].message
      })
    } else {
      close();
      notifications.show({
        icon: <IconCheck size='1.1rem' />,
        title: t('notification.success'),
        color: 'green',
        message: '',
      })
      refetchCustomerData();
      addressForm.reset();
    }
  }

  const { mutate, isLoading } = useCreateCustomerAddress(successCallBack );
  const { mutate: mutateUpdate, isLoading: isUpdateLoading } = useUpdateCustomerAddress(successCallBack);


  const onSubmitForm = (values: AddressInput) => {
    const customerNewValues: ICreateAddressInput = {
      address: {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          country: 'Romania',
          company: '',
          province: values.province,
          city: values.city,
          address1: values.address1,
          address2: values.address2,
          zip: values.zip
      }
    }

    if(isEditMode) {
      customerNewValues.id = initialValues.id;
      return mutateUpdate(customerNewValues)
    } else return mutate(customerNewValues)
  };

  return (
    <>
      <Modal
        size={isMobile ? 'xs' : 'lg'}
        opened={opened}
        onClose={() => {
          addressForm.reset();
          close();
        }}
        classNames={{
          inner: classes.modalBodyWithoutPadding
        }}
        title={isEditMode ?  <Text fz='lg' fw='bold'>{t('addressCard.editAddress')}</Text> : <Text fz='lg' fw='bold'>{t('addressCard.addAddress')}</Text>}
        id={_.uniqueId()}
      >
        <form
            onSubmit={addressForm.onSubmit((values) => onSubmitForm(values))}
        >
        <Grid>
          <Grid.Col xs={6}>
            <Input.Wrapper label={t('form.firstName')} error={addressForm.errors.firstName}>
              <Input placeholder="John" radius="md" {...addressForm.getInputProps("firstName")} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Input.Wrapper label={t('form.lastName')} error={addressForm.errors.lastName}>
              <Input placeholder="Doe" radius="md" {...addressForm.getInputProps("lastName")} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Input.Wrapper label={t('form.phoneNumber')} error={addressForm.errors.phone}>
              <Input placeholder="+40745123123" radius="md" {...addressForm.getInputProps("phone")} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Select
                label={t('addressCard.province')}
                placeholder={t('addressCard.province.details') as string}
                searchable
                nothingFound={t('notFound') as string}
                {...addressForm.getInputProps("province")}
                data={judete}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <Input.Wrapper label={t("addressCard.city")} error={addressForm.errors.city}>
              <Input placeholder={t("addressCard.city")} radius="md" {...addressForm.getInputProps("city")} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Input.Wrapper label={t("addressCard.address1")} error={addressForm.errors.address1}>
              <Input placeholder={t("addressCard.address1")} radius="md" {...addressForm.getInputProps("address1")} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Input.Wrapper label={t("addressCard.address2")}>
              <Input placeholder={t("addressCard.address2")} radius="md" {...addressForm.getInputProps("address2")} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Input.Wrapper label={t("addressCard.postalCode")} error={addressForm.errors.zip}>
              <Input placeholder={t("addressCard.postalCode")} radius="md" {...addressForm.getInputProps("zip")} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Flex justify='center'>
            <Button size="sm" radius="xl" mt='xl' type="submit" loading={isLoading || isUpdateLoading}>
              {isEditMode ? t('edit') : t('addressCard.addAddress')}
            </Button>
            </Flex>
          </Grid.Col>
        </Grid>
        </form>
      </Modal>
    </>
  );
};
