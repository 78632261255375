import { passwordRegex, phoneNumberRegex, validEmailRegex, validStringRegex } from "../utils/constants";

//registerForm
export interface IRegisterFormValues {
    email: string,
    password: string,
    confirmPassword: string,
    firstName: string,
    lastName: string,
    phone: string,
    acceptsMarketing: boolean,
    city: string,
    address: string,
    zipCode: string,
}
export const registerFormInitialValues: IRegisterFormValues = {
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    phone: "",
    acceptsMarketing: false,
    city: "",
    address: "",
    zipCode: "",
};

export const registerFormValidations = (t: any) => {
    return {
        email: (value: string) => (validEmailRegex.test(value) ? null : t("validation.email")),
        password: (value: string) =>
            passwordRegex.test(value)
                ? null
                : t('validation.password'),
        confirmPassword: (value: string, values: IRegisterFormValues) =>
            value !== values.password ? t('validation.confirmPassword') : null,
        firstName: (value: string) =>
            validStringRegex.test(value)
                    ? null
                    : t('validation.firstName'),
        lastName: (value: string) =>
            validStringRegex.test(value)
                    ? null
                    : t('validation.lastName'),
        phone: (value: string) =>
        phoneNumberRegex.test(value) ? null : t('validation.phoneNumber'),
        acceptsMarketing: (value: boolean) =>
            value ? null :t('validation.terms&conditions'),
    }
}
