import { useMutation } from "@tanstack/react-query";
import {createCartQuery, graphQLClient} from "../queries";
import { ICartCreateResponse} from "./cartTypes";
import { getLanguage } from "../../resources/utils";

export const useCreateCart = ( successCallBack: (data: any) => void) => {
    return useMutation(
        ["createCart"],
        (cartInput) => {
            const variables: any = cartInput;
            let language = getLanguage();
            return graphQLClient.request(createCartQuery(language),variables)
        },
        {
            onSuccess: (data: ICartCreateResponse) => {
                successCallBack(data)
            },
            onError: (error: any) => {
                successCallBack({
                    cartCreate: {
                        userErrors: ['we have errors']
                    }
                })
            }
        }
    );
};
