import { Container, Title, Accordion, createStyles, rem } from '@mantine/core';
import BaseContainer from "../BaseContainer/BaseContainer";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
    wrapper: {
        minHeight: 550,
        marginTop: `calc(${theme.spacing.xl} * 4)`
    },

    title: {
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    item: {
        borderRadius: theme.radius.md,
        marginBottom: theme.spacing.lg,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        }`,
    },
}));

export const Faq = () => {
    const { classes } = useStyles();
    const {t} = useTranslation()
    return (
        <BaseContainer>
        <Container size="sm" className={classes.wrapper}>
            <Title align="center" className={classes.title}>
                {t('faq')}
            </Title>

            <Accordion variant="separated">
                <Accordion.Item className={classes.item} value="payments">
                    <Accordion.Control> {t('faq.question1')}</Accordion.Control>
                    <Accordion.Panel> {t('faq.response1')}</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="delivery">
                    <Accordion.Control> {t('faq.question2')}</Accordion.Control>
                    <Accordion.Panel> {t('faq.response2')}</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="returning">
                    <Accordion.Control> {t('faq.question3')}</Accordion.Control>
                    <Accordion.Panel> {t('faq.response3')}</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="sizes">
                    <Accordion.Control> {t('faq.question4')}</Accordion.Control>
                    <Accordion.Panel> {t('faq.response4')}</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="returning2">
                    <Accordion.Control> {t('faq.question5')}</Accordion.Control>
                    <Accordion.Panel> {t('faq.response5')}</Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="sales">
                    <Accordion.Control> {t('faq.question6')}</Accordion.Control>
                    <Accordion.Panel> {t('faq.response6')}</Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Container>
        </BaseContainer>
    );
}
