import React, { forwardRef, FunctionComponent, useEffect, useState } from "react";
import { Grid, Input, Modal, Button, Flex, Text, Avatar, MultiSelect, Group, Textarea } from "@mantine/core";
import { IOrder } from "../utils/api/types";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useStyles } from "../utils/generalStyles";
import { useMediaQuery } from "@mantine/hooks";
import { usePostReturnProduct } from "../utils/api/returnProduct/usePostReturnProduct";


interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <Avatar src={image} />

                <div>
                    <Text>{label}</Text>
                    <Text size="xs" color="dimmed">
                        {description}
                    </Text>
                </div>
            </Group>
        </div>
    )
);

export const ReturnProductModal: FunctionComponent<{
    opened: boolean;
    close: () => void;
    order?: IOrder,
    refetchCustomerData: () => void
}> = ({ opened, close, refetchCustomerData, order }) => {
    const { t } = useTranslation()
    const [dropdownData, setDropdownData] = useState<any[]>([])
    const { classes } = useStyles();
    const isMobile = useMediaQuery("(max-width: 30em)");
    const returnProductForm = useForm({
        initialValues: {
            orderId: '',
            customerNote: '',
            returnLineItems: [],
        },
        validate: {
            returnLineItems: (values) => values.length ? null : 'Alege produsul returnarii',
            customerNote: (values) => values.length > 5 ? null : 'Zi ne motivul',

        }
    });
    const successCallBack = (data: any) => {
        if (data.status !== 200) {
            notifications.show({
                icon: <IconX size='1.1rem' />,
                title: t('notification.error'),
                color: 'red',
                message: t('notification.error.description'),
            })
        } else {
            close();
            notifications.show({
                icon: <IconCheck size='1.1rem' />,
                title: t('notification.success'),
                color: 'green',
                message: t('notification.returnProduct.description'),
            })
            returnProductForm.reset();
        }
    }
    const { mutate, isLoading } = usePostReturnProduct(successCallBack)

    const onSubmitForm = (values: any) => {
        const updatedReturnLineItems: any = {
            products: [],
            returnReason: values.customerNote,
            orderId: order?.id,
            customerEmail: order?.email
        };
        values.returnLineItems.forEach((id: any) => updatedReturnLineItems.products.push(id))

        mutate(updatedReturnLineItems)
    };


    useEffect(() => {
        const dropdownDataUpdated: any[] = [];
        if (order) {
            order.lineItems.nodes.forEach(node =>
                dropdownDataUpdated.push({
                    image: node.variant.image.src || "",
                    label: node.title || "",
                    value: `${node.variant.id} - ${node.title} - ${node.variant.selectedOptions[0].name} ${node.variant.selectedOptions[0].value}`,
                    description: `${node.variant.selectedOptions[0].name} ${node.variant.selectedOptions[0].value}`
                }))
            setDropdownData(dropdownDataUpdated)
        }
    }, [order]);

    return (
        <>
            <Modal
                size={isMobile ? 'xs' : 'lg'}
                opened={opened}
                onClose={() => {
                    close();
                    returnProductForm.reset();
                }}
                title={<Text fz='lg' fw='bold'>Returneaza produse</Text>}
                id={_.uniqueId()}
                classNames={{
                    inner: classes.modalBodyWithoutPadding
                }}
            >
                <form
                    onSubmit={returnProductForm.onSubmit((values) => onSubmitForm(values))}
                >
                    <Grid>
                        <Grid.Col xs={6}>
                            <Input.Wrapper label="Comanda" >
                                <Text fw={500} fz='md'>{order ? order.orderNumber : '#'}</Text>
                            </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <Input.Wrapper label="Procesata in" >
                                <Text fw={500} fz='md'>{order ? new Date(order.processedAt).toLocaleDateString() : '-'} </Text>
                            </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <Input.Wrapper label="Numar produse" >
                                <Text fw={500} fz='md'>{order ? order.lineItems.nodes.length : '-'} </Text>
                            </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <Input.Wrapper label="Total" >
                                <Text fw={500} fz='md'>{order?.totalPrice.amount} {order?.totalPrice.currencyCode} </Text>
                            </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col xs={12}>
                            <MultiSelect
                                label="Alege produsele pe care vrei sa le returnezi"
                                placeholder="Produsele selectate pentru retur"
                                itemComponent={SelectItem}
                                data={dropdownData}
                                searchable
                                nothingFound="Nu am gasit acest produs"
                                maxDropdownHeight={400}
                                {...returnProductForm.getInputProps("returnLineItems")}
                            />
                        </Grid.Col>
                        <Grid.Col xs={12}>
                            <Input.Wrapper label="Motivul returnarii" >
                                <Textarea placeholder="Motivul returnarii" radius="md" {...returnProductForm.getInputProps("customerNote")} />
                            </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col xs={12}>
                            <Flex justify='center'>
                                <Button size="sm" radius="xl" mt='xl' type="submit" loading={isLoading}>
                                    Returneaza
                                </Button>
                            </Flex>
                        </Grid.Col>
                    </Grid>
                </form>
            </Modal>
        </>
    );
};
