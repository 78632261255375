import {Grid, Title, createStyles, Button, Tabs} from "@mantine/core";
import React, {FunctionComponent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import BaseContainer from "../BaseContainer/BaseContainer";
import {IconMessageCircle, IconPhoto, IconSettings} from "@tabler/icons";
import {MyAccountContent} from "./MyAccountContent";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "@mantine/hooks";
import _ from "lodash";

const useStyles = createStyles ( (theme) => ({
    btn: {
        padding: theme.spacing.sm,
        color: theme.colors.dark[7],
        cursor: "pointer",
        borderRadius: theme.radius.xl,
        width: "70%",

        "& > svg": {
            color: theme.colors.dark[7],
            marginRight: theme.spacing.xs,
        },

        "&:hover": {
            backgroundColor: theme.colors.dark[0],
        },
    },
    active: {
        backgroundColor: theme.colors.dark[0],
    },
    tabsText: {
        fontWeight: 600,
    }
}) );

export const MyAccountPage: FunctionComponent = () => {
    const {t} = useTranslation ();
    const {classes} = useStyles ();
    const navigate = useNavigate ()
    useEffect ( () => {
        if (!localStorage.getItem ( 'isLogged' ) || localStorage.getItem ( 'isLogged' ) === 'false') {
            navigate ( "/login" )
        }
    }, [] );
    const isMobile = useMediaQuery ( "(max-width: 30em)" );

    const onLogOut = () => {
        localStorage.removeItem ( 'accessToken' );
        localStorage.removeItem ( 'expiresAt' );
        localStorage.removeItem ( 'id' );
        localStorage.setItem ( 'isLogged', "false" );
        window.location.replace ( '/' )
    }

    return (
        <BaseContainer>
            <Title mt="xl" align="center">
                {t ( "myAccount.title" )}
            </Title>
            <Grid m='xl' mih="45vh">
                <Grid.Col lg={2} xs={0}/>
                <Grid.Col lg={8} xs={12}>
                    <Tabs variant='default' orientation={isMobile ? 'horizontal' : 'vertical'} defaultValue="info"
                          classNames={{
                              tabLabel: classes.tabsText
                          }}
                    >
                        <Tabs.List grow={isMobile}>
                            <Tabs.Tab value="info" icon={<IconPhoto
                                size="1.5rem"/>}>  {t ( "myAccount.accountInformation" )}</Tabs.Tab>
                            <Tabs.Tab value="addresses" icon={<IconMessageCircle
                                size="1.5rem"/>}>  {t ( "myAccount.myAddresses" )}</Tabs.Tab>
                            <Tabs.Tab value="orders"
                                      icon={<IconSettings size="1.5rem"/>}>  {t ( "myAccount.myOrders" )}</Tabs.Tab>
                            {!isMobile ? <Button mt='xl' variant='text' color='dark.7' radius='xl'
                                                 onClick={onLogOut}>
                                {t ( "myAccount.logout" )}
                            </Button> : null}
                        </Tabs.List>

                        <Tabs.Panel value="info">
                            <MyAccountContent key={_.uniqueId ()} activeTab='info'/>
                        </Tabs.Panel>

                        <Tabs.Panel value="addresses">
                            <MyAccountContent key={_.uniqueId ()} activeTab='addresses'/>
                        </Tabs.Panel>

                        <Tabs.Panel value="orders">
                            <MyAccountContent key={_.uniqueId ()} activeTab='orders'/>
                        </Tabs.Panel>
                    </Tabs>
                    {isMobile ? <Button fullWidth mt='xl' variant='text' color='dark.7' radius='xl'
                            onClick={onLogOut}>
                        {t ( "myAccount.logout" )}
                    </Button> : null}
                </Grid.Col>
                <Grid.Col lg={2} xs={0}/>
            </Grid>
        </BaseContainer>
    );
};
