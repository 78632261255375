import {useQuery} from "@tanstack/react-query";
import {getArticlesQuery, graphQLClient} from "../queries";
import { getLanguage } from "../../resources/utils";

export interface IArticlesResponse {
        articles: Articles
  }
  
  export interface Articles {
    edges: ArticleEdge[]
  }
  
  export interface ArticleEdge {
    node: Node
  }
  
  export interface Node {
    id: string
    contentHtml: string
    title: string
    image: {
        url: string
    }
    tags: string[]
    excerpt: string
  }
  
export const useGetArticles = (successCallback: (data: IArticlesResponse) => void = () => null) => {

    let language = getLanguage();
    return useQuery ( ['readArticles'], () => graphQLClient.request ( getArticlesQuery(language) ), {
        onSuccess: (data: IArticlesResponse) => {
            successCallback(data)
            return data
        },
        onError: (err) => {
            console.log ( err );
        },
        enabled: false,
        cacheTime: 0 
    } );
};

