import {CartProduct} from "../redux/ShoppingCart/shoppingCartSlice";

export const calculateTotalPrice = (initialPriceValue: number,state: { cartItems: CartProduct[], transportPrice: number, totalPrice: number }) => {
    let updatedTotalPrice: number = initialPriceValue;
    state.cartItems.forEach((elem: CartProduct) => {
            updatedTotalPrice = Number((updatedTotalPrice + Number(elem.price) * elem.quantity).toFixed(2))
        }
    )

    return updatedTotalPrice;
}

export const getLanguage = () =>  {
    const language = localStorage.getItem('i18nextLng');
    if(language && language.includes('en')) {
        return 'EN'
    } else if (language && language.includes('ro')) {
        return 'RO';
    } else return 'EN';
}