import { useMutation } from "@tanstack/react-query";

export const usePostReturnProduct = ( successCallBack: (data: any) => void) => {
  return useMutation(
    ["sendReturnProductForm"],
    (returnProductForm: any) => {
      return fetch('https://slwactive-be.onrender.com/api/v1/return', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(returnProductForm)
        })
    },
    {
      onSuccess: (data: any) => {
        successCallBack(data)
      },
      onError: (error: any) => {
        console.log(error)
      }
    }
  );
};
