import { Title, Text, Container, Button, createStyles } from '@mantine/core';
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { pushClickOnCategoryDataLayer } from '../utils/functions';

const useStyles = createStyles((theme) => ({
    wrapper: {
        height: '45rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.fn.smallerThan('xs')]: {
            height: '30rem',
        },

        '& > div > video': {
            objectFit: 'cover',
            objectPosition: 'center',
            height: '45rem',
            width: ' 100vw',

            [theme.fn.smallerThan('xs')]: {
                height: '30rem',
            },
        }
    },

    inner: {
        position: 'absolute',
        zIndex: 1,
    },

    title: {
        fontWeight: 800,
        fontSize: '3rem',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        color: theme.white,
        marginBottom: theme.spacing.xs,
        textAlign: 'center',
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,

        [theme.fn.smallerThan('xs')]: {
            fontSize: '2rem',
        },
    },

    highlight: {
        color: theme.colors[theme.primaryColor][4],
    },

    description: {
        color: theme.colors.gray[0],
        textAlign: 'center',

        [theme.fn.smallerThan('xs')]: {
            fontSize: theme.fontSizes.md,
        },
    },

    controls: {
        marginTop: `calc(${theme.spacing.xl} * 1.5)`,
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
            '& > button': {
                width: '75%'
            }
        },
    },

    control: {
        fontSize: theme.fontSizes.md,

        '&:not(:first-of-type)': {
            marginLeft: theme.spacing.md,
        },

        [theme.fn.smallerThan('xs')]: {
            '&:not(:first-of-type)': {
                marginTop: theme.spacing.md,
                marginLeft: 0,
            },
        },
    },
}));

const HeroImageBackgroundWithButtons: FunctionComponent = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onCategoryClick = (route: string) => {
        navigate(`/${route}`);
    };

    return (
        <div className={classes.wrapper}>
            <div
                dangerouslySetInnerHTML={{
                    __html: `<video autoplay loop muted playsinline poster='https://cdn.shopify.com/s/files/1/0770/7017/2509/files/posterVideo.png?v=1702032209'>
                      <source src='https://cdn.shopify.com/videos/c/o/v/4a425445ef26406b96df4c0db9d1d748.mp4' type="video/mp4" />
                      Your browser does not support the video tag.
                     </video>`,
                }}
            />
            <div className={classes.inner}>
                <Title className={classes.title}>
                    {t("heroImg.title")}
                </Title>

                <Container size={640}>
                    <Text pb='xl' size="lg" className={classes.description}>
                        {t("heroImg.subTitle")}
                    </Text>
                </Container>

                <div className={classes.controls}>
                    <Button onClick={() => {
                        pushClickOnCategoryDataLayer('women');
                        onCategoryClick("products?type=women")
                    }} className={classes.control}
                        color='gray' size="lg" radius='xl'>
                        {t("heroImg.shopWomen")}
                    </Button>
                    <Button onClick={() => {
                        pushClickOnCategoryDataLayer('men');
                        onCategoryClick("products?type=men")
                    }} className={classes.control}
                        size="lg" radius='xl'>
                        {t("heroImg.shopMen")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default HeroImageBackgroundWithButtons;
