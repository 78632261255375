import React, {FunctionComponent, ReactNode, useEffect} from "react";
import {Button, Container, Dialog, Group, Text} from "@mantine/core";
import {StoreHeader} from "../StoreHeader/StoreHeader";
import Announcement from "../Announcement/Announcement";
import {StoreFooter} from "../StoreFooter/StoreFooter";
import {
    useGetCustomerDetails,
} from "../utils/api/customers/useGetCustomerDetails";
import {ICustomerDetailsResponse} from "../utils/api/types";
import {useDisclosure, useWindowScroll} from "@mantine/hooks";
import {useDispatch} from "react-redux";
import {setCustomer} from "../utils/redux/Customer/customerSlice";
import { Trans, useTranslation } from "react-i18next";

export interface IBaseContainer {
    children: ReactNode;
}

const BaseContainer: FunctionComponent<IBaseContainer> = ({children}) => {
    const accessToken: string | null = localStorage.getItem ( "accessToken" );
    const [opened, { toggle,close }] = useDisclosure(false);
    const {t} = useTranslation();
    //@ts-ignore
    const [scroll] = useWindowScroll ();
    const dispatch = useDispatch ();
    const succesCallBack = (data: ICustomerDetailsResponse) => {
        if (data && data.customer && data.customer.id) {
            localStorage.setItem ( "id", data.customer.id );
            dispatch ( setCustomer ( data.customer ) )
        } else {
            localStorage.setItem ( "isLogged", "false" );
            localStorage.removeItem ( "id" );
            localStorage.removeItem ( "accessToken" );
            localStorage.removeItem ( "expiresOn" );
        }
    };
    const {refetch: refetchCustomerDetails, data} = useGetCustomerDetails (
        accessToken || "",
        succesCallBack
    );

    useEffect ( () => {
        if (localStorage.getItem ( "isLogged" ) === "true") {
            refetchCustomerDetails ()
        }
        if (!localStorage.getItem("cookiesAccepted")) {
            toggle();
        }
    }, [] );
    return (
        <Container w="100vw" maw="100vw" h="100vh" mah="100vh" px="0">
            <Announcement
                firstName={data?.customer ? data.customer.firstName : undefined}
            />
            <StoreHeader/>
            <div
                id='stickyHeader'
                style={{
                    position: 'fixed',
                    top: (scroll.y > 200) ? '0' : '-100px',
                    width: "100%",
                    zIndex: "10",
                    transition: 'top 0.2s ease-in-out',
                }}>
                <StoreHeader/>
            </div>
            <Container w="100vw" maw="100vw" px="0">
                {children}
            </Container>
            <StoreFooter/>
            <Dialog opened={opened} size="xl" radius="xl">
                <Text size="md" mb="xs" weight={700} align="center">
                    {t("cookies.title")}
                </Text>

                <Group align="center" position="center">
                    <Text size="sm" mb="xs" weight={500} align="center">
                        {<Trans
                            i18nKey="cookies.body"
                            components={[<b />, <br />, <br />, <b/>]}
                        />}
                    </Text>
                    <Button onClick={() => {
                        localStorage.setItem("cookiesAccepted", "all");
                        close();
                    }}> {t('cookies.acceptAll')} </Button>
                    <Button variant="subtle"
                        onClick={() => {
                            localStorage.setItem("cookiesAccepted", "essential");
                            close();
                        }}>
                       {t('cookies.acceptEssential')} </Button>
                </Group>
            </Dialog>
        </Container>
    );
};

export default BaseContainer;
