import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  createStyles,
  Image,
  Flex,
} from "@mantine/core";
import React, {FunctionComponent, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../utils/resources/logo2.svg";
import Announcement from "../Announcement/Announcement";
import {
  useLoginCustomer
} from "../utils/api/customers/useLoginCustomer";
import { useForm } from "@mantine/form";
import {ICustomerLoginInput, ILoginDataResponse} from "../utils/api/types";
import {loginErrorCodes} from "../utils/api/errorCodes";
import {notifications} from "@mantine/notifications";
import {IconX} from "@tabler/icons";
import {useDisclosure} from "@mantine/hooks";
import ResetPasswordModal from "./ResetPasswordModal";
import { validEmailRegex } from "../utils/constants";

const useStyles = createStyles((theme) => ({
  containerRoot: {
    backgroundSize: "cover",
    background: theme.colors.gray[1],
  },
}));

export const LoginPage: FunctionComponent = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openedResetPasswordModal, { open: openResetPasswordModal, close: closeResetPasswordModal }] = useDisclosure(false);
  const [rememberMe,setRememberMe] = useState(true);

  const onAnchorClick = () => {
    navigate(`/register`);
  };
  const loginForm = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (validEmailRegex.test(value) ? null : t('validation.email')),
      password: (value) =>
        value.length > 3
          ? null
          : t('validation.login.password'),
    },
  });
  const successCallBack = (data: ILoginDataResponse) => {
    if(data?.customerAccessTokenCreate.customerUserErrors.length > 0) {
      if(data?.customerAccessTokenCreate.customerUserErrors[0].code === loginErrorCodes.inexistentAccount) {
        notifications.show({
          icon: <IconX size='1.1rem' />,
          title: t('notification.error.loginError'),
          color: 'red',
          message: data?.customerAccessTokenCreate.customerUserErrors[0].message
        })
      }
    }
    if(data?.customerAccessTokenCreate.customerAccessToken !== null) {
      localStorage.setItem("accessToken",data.customerAccessTokenCreate.customerAccessToken.accessToken);
      localStorage.setItem("expiresAt",data.customerAccessTokenCreate.customerAccessToken.expiresAt);
      localStorage.setItem("isLogged","true");
      navigate('/');
    }
  }
  const { mutate, isLoading } = useLoginCustomer(successCallBack);

  const onSubmitForm = (values: any) => {
    const customerValues: ICustomerLoginInput = {
      input: {
        email: values.email,
        password: values.password,
      },
    };
    mutate(customerValues);
  };
  return (
    <Container
      w="100vw"
      maw="100vw"
      h="100vh"
      mah="100vh"
      px="0"
      className={classes.containerRoot}
    >
      <Announcement />
      <Flex justify={"center"}>
        {" "}
        <Image
          onClick={() => navigate("/")}
          height={100}
          width="auto"
          src={logo}
          alt="logo"
          sx={{ cursor: "pointer" }}
        />{" "}
      </Flex>

      <Container size={420} my='lg'>
        <Title align="center"> {t("login.title")}</Title>
        <Text color="gray.7" size="lg" align="center" mt={5}>
          {t("login.question1")}{" "}
          <Anchor
            size="lg"
            component="button"
            color="gray.9"
            type="button"
            onClick={() => onAnchorClick()}
          >
            {t("login.createAccount")}
          </Anchor>
        </Text>
        <form
            onSubmit={loginForm.onSubmit((values) => onSubmitForm(values))}
        >
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            label="Email"
            placeholder="you@slwactive.com"
            {...loginForm.getInputProps("email")}
          />
          <PasswordInput
            label={t("login.password")}
            placeholder={t("login.passwordLabel") as string}
            mt="md"
            {...loginForm.getInputProps("password")}
          />
          <Group position="apart" mt="lg">
            <Checkbox label={t("login.rememberMe")} checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}/>
            <Anchor component="button" size="sm" color="gray.9" onClick={openResetPasswordModal}>
              {t("login.forgotPassword")}
            </Anchor>
          </Group>
          <Button loading={isLoading} variant="filled" radius="xl" mt="xl" fullWidth  type="submit">
            {t("login.signIn")}
          </Button>
        </Paper>
        </form>
      </Container>
      <ResetPasswordModal opened={openedResetPasswordModal} onClose={closeResetPasswordModal} />
    </Container>
  );
};
