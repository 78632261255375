import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Customer} from "../../api/types";

const initialState: {
    customer: Customer | null
} = {
   customer: null
}

export const customerSlice = createSlice({
    name: 'customerSlice',
    initialState: initialState,
    reducers: {
       setCustomer: (state,action: PayloadAction<Customer>) => {
           state.customer = action.payload;
       },
        resetCustomer: (state,action) => {
           state.customer = null;
        }
    }
})
export const {
   setCustomer,
   resetCustomer
} = customerSlice.actions

export default customerSlice.reducer;
