import React, {FunctionComponent} from "react";
import {Card, Text, Button, Grid, Flex, Stack, Modal, Loader, Center} from "@mantine/core";
import {IconCheck, IconMapPin, IconX} from "@tabler/icons";
import {Address, AddressInput} from "../utils/api/types";
import {useDisclosure} from "@mantine/hooks";
import {useDeleteCustomerAddress} from "../utils/api/customers/useDeleteCustomerAddress";
import {AddressModal} from "./AddressModal";
import {useTranslation} from "react-i18next";
import {useStyles} from "../utils/generalStyles";
import { notifications } from "@mantine/notifications";

export const AddressCard: FunctionComponent<{
    address: Address;
    index: number;
    refetchData: any
}> = ({
          refetchData,
          address: {id, company, country, firstName, lastName, name, phone, city, province, address1, address2, zip},
      }) => {
    const {t} = useTranslation ();

    const [
        openedEditAddressModal,
        {open: openEditAddressModal, close: closeEditAddressModal},
    ] = useDisclosure ( false );
    const [
        openedDeleteAddressModal,
        {open: openDeleteAddressModal, close: closeDeleteAddressModal},
    ] = useDisclosure ( false );
    const {classes} = useStyles();

    const initialValues: AddressInput = {
        address1: address1,
        address2: address2,
        firstName: firstName,
        lastName: lastName,
        city: city,
        province: province,
        company: company,
        country: country,
        phone: phone,
        zip: zip,
        id: id
    }

    const onSuccessDelete = (data: any) => {
        if (data.customerUserErrors.length) {
            notifications.show ( {
                icon: <IconX size='1.1rem'/>,
                title: t ( 'notification.error' ),
                color: 'red',
                message: data.customerUserErrors[0].message
            } )
        } else {
            notifications.show ( {
                icon: <IconCheck size='1.1rem'/>,
                title: t ( 'notification.success' ),
                color: 'green',
                message: ''
            } )
        closeDeleteAddressModal ()
        refetchData ()
        }

    }

    const {mutate, isLoading} = useDeleteCustomerAddress ( onSuccessDelete );

    const onConfirmDelete = () => {
        mutate ( {
            id: id
        } )
    }

    return (
        <>
            {isLoading ?
                <Center>
                    <Loader color='gray'/>
                </Center> :
                <>
                    <Card shadow="sm" radius="md" withBorder>
                        <Grid>
                            <Grid.Col xs={12}>
                                <Flex justify="center">
                                    <IconMapPin/>
                                    <Text mx="md" fw={500}>
                                        {t ( 'addressCard.address' )}
                                    </Text>
                                </Flex>
                            </Grid.Col>
                            <Grid.Col xs={12}>
                                <Stack spacing={0} align="center">
                                    <Text color="dark.2" fs="xs">
                                        {t ( 'addressCard.contactPerson' )}
                                    </Text>
                                    <Text fw={500} fs="xs" align="center">
                                        {name}
                                    </Text>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Stack spacing={0} align="center">
                                    <Text color="dark.2" fs="xs">
                                        {t ( 'addressCard.address' )}
                                    </Text>
                                    <Text fw={500} fs="xs" align="center">
                                        {province}
                                    </Text>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Stack spacing={0} align="center">
                                    <Text color="dark.2" fs="xs">
                                        {t ( 'addressCard.city' )}
                                    </Text>
                                    <Text fw={500} fs="xs" align="center">
                                        {city}
                                    </Text>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Stack spacing={0} align="center">
                                    <Text color="dark.2" fs="xs">
                                        {t ( 'addressCard.address1' )}
                                    </Text>
                                    <Text fw={500} fs="xs" align="center">
                                        {address1}
                                    </Text>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Stack spacing={0} align="center">
                                    <Text color="dark.2" fs="xs">
                                        {t ( 'addressCard.address2' )}
                                    </Text>
                                    <Text fw={500} fs="xs" align="center">
                                        {address2}
                                    </Text>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Stack spacing={0} align="center">
                                    <Text color="dark.2" fs="xs">
                                        {t ( 'addressCard.postalCode' )}
                                    </Text>
                                    <Text fw={500} fs="xs" align="center">
                                        {zip}
                                    </Text>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Stack spacing={0} align="center">
                                    <Text color="dark.2" fs="xs">
                                        {t ( 'addressCard.phoneNumber' )}
                                    </Text>
                                    <Text fw={500} fs="xs" align="center">
                                        {phone}
                                    </Text>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col xs={12}>
                                <Flex m="xl" justify={"center"}>
                                    <Button size="sm" radius="xl" variant="outline" mx="md"
                                            onClick={openDeleteAddressModal}>
                                        {t ( 'delete' )}
                                    </Button>
                                    <Button size="sm" radius="xl" onClick={openEditAddressModal}>
                                        {t ( 'edit' )}
                                    </Button>
                                </Flex>
                            </Grid.Col>
                        </Grid>
                    </Card>
                    <Modal
                        size='sm'
                        title={<Text fz='lg' fw='bold' align='center'>{t ( 'addressCard.deleteQuestion' )}</Text>}
                        opened={openedDeleteAddressModal} onClose={closeDeleteAddressModal}
                        classNames={{
                            inner: classes.modalBodyWithoutPadding
                        }}>
                        <Flex mt='md' justify={"center"}>
                            <Button size="sm" radius="xl" onClick={onConfirmDelete} loading={isLoading}>
                                {t ( 'delete' )}
                            </Button>
                            <Button size="sm" radius="xl" variant="outline" mx="md" onClick={closeDeleteAddressModal}
                                    loading={isLoading}>
                                {t ( 'addressCard.cancel' )}
                            </Button>
                        </Flex>
                    </Modal>
                    <AddressModal refetchCustomerData={refetchData} initialValues={initialValues} isEditMode={true}
                                  opened={openedEditAddressModal} close={closeEditAddressModal}/>
                </>
            }
        </>
    );
};
