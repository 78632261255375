import React, { FunctionComponent, useEffect, useState } from "react";
import BaseContainer from "../BaseContainer/BaseContainer";
import {
    Image,
    Grid,
    Loader,
    createStyles,
    Flex,
    Text,
    Stack,
    Title,
    SegmentedControl,
    Button,
    TypographyStylesProvider,
    rem, Center, Box
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProductDetails } from "../utils/api/products/useGetProductDetails";
import { IconCheck, IconTruckDelivery, IconTruckReturn } from "@tabler/icons";
import { Carousel } from "@mantine/carousel";
import ProductCardSimple from "../ProductCardSimple/ProductCardSimple";
import { useGetProducts } from "../utils/api/products/useGetProducts";
import { useDisclosure, useWindowScroll } from "@mantine/hooks";
import { useDispatch } from "react-redux";
import { addInCart } from "../utils/redux/ShoppingCart/shoppingCartSlice";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { IProductDetailsResponse } from "../utils/api/types";
import _ from "lodash"
import { SizeGuideModal } from "../SizeGuideModal/SizeGuideModal";
import i18next from "i18next";
import { pushAddToCartDataLayer, pushCheckSizeGuideDataLayer, pushClickOnProductDataLayer } from "../utils/functions";


const useStyles = createStyles((theme) => ({
    carouselIndicator: {
        width: rem(12),
        height: rem(4),
        transition: 'width 250ms ease',
        '&[data-active]': {
            width: rem(40),
        },
    },
    carouselRoot: {
        position: 'sticky',
        top: theme.spacing.xl
    },
    principalImg: {
        height: `100% !important`,
    },
    centerDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    textBtn: {
        cursor: "pointer",

        '&:hover': {
            textDecoration: 'underline',
        }
    },
    videoWrapper: {
        height: '42.5rem',
        [theme.fn.smallerThan('sm')]: {
            height: '15rem'
        },

        '& > div > video': {
            objectFit: 'contain',
            objectPosition: 'center',
            height: '100%',
            width: ' 100%',
        }
    },
}));

const ProductPage: FunctionComponent = () => {
    let { productId } = useParams();
    const { classes } = useStyles();
    const { t } = useTranslation()
    // eslint-disable-next-line
    const [scroll, scrollTo] = useWindowScroll();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isOpenSizeGuideModal, { open: openSizeGuideModal, close: closeSizeGuideModal }] = useDisclosure(false);
    const [sizesObject, setSizesObject] = useState<{
        label: string,
        value: string
    }[]>([]);
    const [selectedSize, setSelectedSize] = useState('');
    const {
        data: dataTrending,
        isLoading: isLoadingTreding,
        refetch: refetchTrending,
    } = useGetProducts(4, "all", []);


    const successCallBack = (data: IProductDetailsResponse) => {
        const updatedSizesObject: {
            label: string,
            value: string
        }[] = [];
        data.product.options[0].values.forEach(size => updatedSizesObject.push({
            value: size,
            label: size
        }))
        setSelectedSize(data.product.options[0].values[0])
        setSizesObject(updatedSizesObject)
    }
    const {
        refetch,
        isLoading,
        isRefetching,
        data
    } = useGetProductDetails(`gid://shopify/Product/${productId}`, successCallBack)


    useEffect(() => {
        refetch();
        refetchTrending();
        scrollTo({ y: 0 })
    }, [productId, i18next.language]);
    const salePrice: number = Number(data?.product.priceRange.maxVariantPrice.amount) + Number(data?.product.priceRange.maxVariantPrice.amount) * 0.3;

    return (
        <>
            <BaseContainer>
                <Grid m='xl'>
                    <Grid.Col lg={2} md={0} />
                    {isLoading || isRefetching ?
                        <Center>
                            <Loader color='gray' />
                        </Center> : <>
                            <Grid.Col lg={5} md={6}>
                                <Carousel
                                    maw='100%'
                                    withIndicators
                                    controlSize={40}
                                    loop
                                    classNames={{
                                        indicator: classes.carouselIndicator,
                                        root: classes.carouselRoot
                                    }}
                                >
                                    {data?.product.media.edges.map((obj, index) =>
                                        <Carousel.Slide key={_.uniqueId()}>
                                            {obj.node.mediaContentType === "IMAGE" ?
                                                <Image key={_.uniqueId()} fit='contain' src={obj.node.image?.url + '&height=1080'}
                                                    alt={obj.node.alt}
                                                    classNames={{
                                                        image: classes.principalImg
                                                    }}
                                                /> :
                                                <Box
                                                  className={classes.videoWrapper}
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: `<video autoplay loop muted playsinline poster='${obj.node.sources ? obj.node.previewImage.src : ''}' >
                                                  <source src='${obj.node.sources ? obj.node.sources[0].url : ''}' type="video/${obj.node.sources ? obj.node.sources[0].format : ''}" />
                                                  Your browser does not support the video tag.
                                                 </video>`,
                                                        }}
                                                    />
                                                </Box>
                                            }
                                        </Carousel.Slide>
                                    )}
                                
                                </Carousel>
                            </Grid.Col>
                            <Grid.Col lg={3} md={6}>
                                <Stack mt='xl' align='left'>
                                    <Title align='center' order={3}>{data?.product.title}</Title>
                                    <Flex justify='center' gap='xl'>
                                        <Title
                                            align='center'
                                            order={4}>{data?.product.priceRange.maxVariantPrice.amount} {data?.product.priceRange.maxVariantPrice.currencyCode}</Title>
                                        {data?.product.tags.includes('sales') && <Title
                                            align='center'
                                            color='red'
                                            strikethrough
                                            order={4}>{salePrice.toFixed(2)} {data?.product.priceRange.maxVariantPrice.currencyCode}</Title>
                                        }
                                    </Flex>
                                    <Flex direction={"column"} align='center'>
                                        <Flex align={'center'} justify={'space-between'} w='90%'>
                                            <Text fw={600} mb='sm'>{t('product.chooseSize')}</Text>
                                            <Text fw={600} fz='sm' mb='sm' className={classes.textBtn}
                                                onClick={() => {
                                                    openSizeGuideModal();
                                                    pushCheckSizeGuideDataLayer(data?.product.title || "")
                                                }}>
                                               {t('sizeModal.title')}
                                            </Text>
                                        </Flex>
                                        <SegmentedControl
                                            size='sm'
                                            w='90%'
                                            onChange={setSelectedSize}
                                            value={selectedSize}
                                            data={sizesObject}
                                        />
                                    </Flex>
                                    <Center>
                                        <Button
                                            variant="filled"
                                            color="primary"
                                            radius="xl"
                                            my='xl'
                                            w='60%'
                                            size='md'
                                            onClick={() => {
                                                if (data) {
                                                    let selectedVariantId = '';
                                                    data?.product.variants.nodes.find(e => {
                                                        if (e.title === selectedSize) {
                                                            selectedVariantId = e.id;
                                                        }
                                                    })
                                                    dispatch(addInCart({
                                                        quantity: 1,
                                                        prodUuid: _.uniqueId(),
                                                        merchandiseId: data?.product.options[0].id,
                                                        variantId: selectedVariantId,
                                                        title: data?.product.title,
                                                        price: data?.product.priceRange.maxVariantPrice.amount,
                                                        currency: data?.product.priceRange.maxVariantPrice.currencyCode,
                                                        image: data.product.featuredImage.src,
                                                        size: selectedSize
                                                    }))
                                                    pushAddToCartDataLayer(data?.product.title,selectedSize,data?.product.priceRange.maxVariantPrice.amount)
                                                    notifications.show({
                                                        icon: <IconCheck size='1.1rem' />,
                                                        title: t('notification.addToBag.title'),
                                                        color: 'green',
                                                        message: `${data.product.title}, size ${selectedSize}, ${t('notification.addToBag.message')}`,
                                                    })
                                                }
                                            }}
                                        >
                                            {t('product.addToBag')}
                                        </Button>
                                    </Center>
                                    <TypographyStylesProvider px='xl'>
                                        <div dangerouslySetInnerHTML={{ __html: data?.product.descriptionHtml || '' }} />
                                    </TypographyStylesProvider>
                                    <Grid align='flex-start' justify='center' px='xl'>
                                        <Grid.Col span={6} className={classes.centerDiv}>
                                            <IconTruckDelivery size={60} color='#626F92' stroke='1.2' />
                                            <div>
                                                <Text align={"center"} fz='md'
                                                    fw={700}>{t('product.deliveryTime')}</Text>
                                                <Text align={"center"} fz='md'
                                                    fw={500}>{t('product.deliveryTime.description')}</Text>
                                            </div>
                                        </Grid.Col>
                                        <Grid.Col span={6} className={classes.centerDiv}>
                                            <IconTruckReturn size={60} color='#626F92' stroke='1.2' />
                                            <div>
                                                <Text align={"center"} fz='md' fw={700}>{t('product.return')}</Text>
                                                <Text align={"center"} fz='md'
                                                    fw={500}>{t('product.return.description')}</Text>
                                            </div>
                                        </Grid.Col>
                                    </Grid>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col lg={2} md={0} />
                            <Grid.Col xs={12}>
                                <Title m="xl" order={2} color="dark">
                                    {t('product.mightLike')}
                                </Title>
                                {isLoadingTreding && !dataTrending ? (
                                    <Center>
                                        <Loader color='gray' />
                                    </Center>
                                ) : (
                                    <Grid mx="sm" gutter={50}>
                                        {dataTrending &&
                                            dataTrending.collection.products.edges.map((product) => (
                                                <Grid.Col xs={12} sm={6} md={6} xl={3} key={_.uniqueId()}>
                                                    <ProductCardSimple
                                                        key={_.uniqueId()}
                                                        imgUrl={product.node.featuredImage.src }
                                                        title={product.node.title}
                                                        price={product.node.priceRange.maxVariantPrice.amount}
                                                        tags={product.node.tags}
                                                        currencyCode={
                                                            product.node.priceRange.maxVariantPrice.currencyCode
                                                        }
                                                        onClick={() => {
                                                            pushClickOnProductDataLayer(product.node.title);
                                                            navigate(`/product/${product.node.id.split("Product/")[1]}`)
                                                        }}
                                                    />
                                                </Grid.Col>
                                            ))}
                                    </Grid>
                                )}
                            </Grid.Col>
                        </>}
                </Grid>
            </BaseContainer>
            <SizeGuideModal productTags={data?.product.tags} opened={isOpenSizeGuideModal} onClose={closeSizeGuideModal} />
        </>
    );
};

export default ProductPage;
