import {createStyles, Text, Container, ActionIcon, Group, Image} from '@mantine/core';
import {IconBrandInstagram, IconBrandTiktok} from '@tabler/icons';
import logo from "../utils/resources/logo2.svg";
import anpcSal from "../utils/resources/anpc-sal.avif";
import anpcSol from "../utils/resources/anpc-sol.avif";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {redirect} from "react-router-dom";
import React from "react";


const useStyles = createStyles ( (theme) => ({
    footer: {
        marginTop: 120, //@ts-ignore
        paddingTop: theme.spacing.xl * 2, //@ts-ignore
        paddingBottom: theme.spacing.xl * 2,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
    },

    logo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 350,

        [theme.fn.smallerThan ( 'sm' )]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    description: {
        marginTop: 5,

        [theme.fn.smallerThan ( 'sm' )]: {
            marginTop: theme.spacing.xs,
            textAlign: 'center',
        },
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing.xl,

        [theme.fn.smallerThan ( 'sm' )]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    groups: {
        display: 'flex',
        flexWrap: 'wrap',

        [theme.fn.smallerThan ( 'sm' )]: {
            display: 'none',
        },
    },

    wrapper: {
        width: 160,
    },

    link: {
        display: 'block',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
        fontSize: theme.fontSizes.sm,
        paddingTop: 3,
        paddingBottom: 3,

        '&:hover': {
            textDecoration: 'underline',
        },
    },

    title: {
        fontSize: theme.fontSizes.lg,
        fontWeight: 700,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`, //@ts-ignore
        marginBottom: theme.spacing.xs / 2,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    afterFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing.xl,
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,

        [theme.fn.smallerThan ( 'sm' )]: {
            flexDirection: 'column',
            gap: theme.spacing.xs
        },
    },

    displayOnMobile: {
        display: 'none',

        [theme.fn.smallerThan ( 'sm' )]: {
            display: 'block'
        }
    },

    social: {
        [theme.fn.smallerThan ( 'sm' )]: {
            marginTop: theme.spacing.xs,
        },
    },
}) );

export const StoreFooter = () => {
    const {classes} = useStyles ();
    const {t} = useTranslation ();
    const data = [
        {
            title: t ( 'footer.pages' ),
            links: [
                {
                    label: t ( 'footer.aboutUs' ),
                    link: "/aboutus",
                },
                {
                    label: t ( 'footer.faq' ),
                    link: "/faq",
                },
                {
                    label: t ( 'footer.contactUs' ),
                    link: "/contact",
                },
                {
                    label: t ( 'footer.articles' ),
                    link: "/articles",
                },
            ],
        },
        {
            title: t ( 'footer.account' ),
            links: [
                {
                    label: t ( 'footer.login' ),
                    link: "/login",
                },
                {
                    label: t ( 'footer.register' ),
                    link: "/register",
                }
            ],
        },
        {
            title: t ( 'footer.privacy' ),
            links: [
                {
                    label: t ( 'footer.termsAndConditions' ),
                    link: "/termsAndConditions.pdf",
                },
                {
                    label: t ( 'footer.confidentialityPolicy' ),
                    link: "/confidentialityPolicy.pdf",
                },
                {
                    label: t ( 'footer.returnPolicy' ),
                    link: "/termsAndConditions.pdf",
                },
                {
                    label: t ( 'footer.deliveryInformation' ),
                    link: "/termsAndConditions.pdf",
                }
            ],
        },
    ]
    const groups = data.map ( (group) => {
        const links = group.links.map ( (link, index) => (
            <Text<'a'>
                key={_.uniqueId ()}
                className={classes.link}
                component="a"
                href={link.link}
                onClick={() => redirect ( link.link )}
            >
                {link.label}
            </Text>
        ) );

        return (
            <div className={classes.wrapper} key={_.uniqueId ()}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    } );

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <Image height={100} width="auto" src={logo} alt="logo-slwactive"/>
                    <Text size="xs" align='center' color="dimmed" className={classes.description}>
                        {t ( 'footer.brandDescription' )}
                    </Text>
                </div>
                <div className={classes.groups}>{groups}</div>
            </Container>
            <Container className={classes.afterFooter}>
                <Text align='center' color="dimmed" size="sm">
                    {t ( 'footer.rightsReserved' )} © {new Date ().getFullYear ()} SLW Empr S.R.L
                </Text>
                <Text<'a'>
                    key={_.uniqueId ()}
                    className={`${classes.link} ${classes.displayOnMobile}`}
                    component="a"
                    href='/contact'
                    onClick={() => redirect ( '/contact' )}
                >
                    {t ( 'footer.mobile.contactUs' )}
                </Text>
                <a target='_blank' href='https://anpc.ro/ce-este-sal/' rel="noreferrer">
                    <Image height={50} width="auto" src={anpcSal} alt="anpc-sal"/>
                </a>
                <a target='_blank' href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO'
                   rel="noreferrer">
                    <Image height={50} width="auto" src={anpcSol} alt="anpc-sol"/>
                </a>
                <Group spacing={0} className={classes.social} position="right" noWrap>
                    <ActionIcon size="lg"
                                onClick={() => window.open ( 'https://www.instagram.com/slwactive', '_blank' )}>
                        <IconBrandInstagram size={50} stroke={1.5} color='#cd486b'/>
                    </ActionIcon>
                    <ActionIcon size="lg"
                                onClick={() => window.open ( 'https://www.tiktok.com/@slwactive', '_blank' )}>
                        <IconBrandTiktok size={50} stroke={1.5} color='#000'/>
                    </ActionIcon>
                </Group>
            </Container>
        </footer>
    );
}
