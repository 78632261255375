import { Title, Text, Container, Overlay, createStyles } from '@mantine/core';
import React,{FunctionComponent} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: '10rem',
        paddingBottom: '10rem',
        backgroundImage:
            `url('https://cdn.shopify.com/s/files/1/0770/7017/2509/files/gym.jpg?v=1702032219')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginTop: `${theme.spacing.xl}`,
        cursor: 'pointer',

        [theme.fn.smallerThan('xs')]: {
            paddingTop: '8rem',
            paddingBottom: '8rem',
        },
    },

    inner: {
        position: 'relative',
        zIndex: 1,
    },

    title: {
        fontWeight: 800,
        fontSize: '3rem',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        color: theme.white,
        marginBottom: theme.spacing.xs,
        textAlign: 'center',
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,

        [theme.fn.smallerThan('xs')]: {
            fontSize: '2rem',
        },
    },

    highlight: {
        color: theme.colors[theme.primaryColor][4],
    },

    description: {
        color: theme.colors.gray[0],
        textAlign: 'center',

        [theme.fn.smallerThan('xs')]: {
            fontSize: theme.fontSizes.md,
        },
    }
}));

const HeroImageBackgroundSimple: FunctionComponent = () => {
    const { classes } = useStyles();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const onCategoryClick = (route: string) => {
        navigate(`/${route}`);
    };

    return (
        <div className={classes.wrapper} onClick={() => onCategoryClick('products')}>
            <Overlay color="#000" opacity={0.5} zIndex={1} />
            <div className={classes.inner}>
                <Title className={classes.title}>
                    {t("heroImg2.title")}
                </Title>
                <Container size={640}>
                    <Text pb='xl' size="lg" className={classes.description}>
                        {t("heroImg2.description")}
                    </Text>
                </Container>
            </div>
        </div>
    );
}

export default HeroImageBackgroundSimple;
