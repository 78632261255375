import React, { FunctionComponent, useRef } from "react";
import { Alert, Divider, Flex, Grid, Text } from "@mantine/core";
import { IconStar, IconTruckDelivery, IconTruckReturn } from "@tabler/icons";
import { Link } from "react-router-dom";
import LanguagePicker from "../LanguagePicker/LanguagePicker";
import { useStyles as generalUseStyles } from "../utils/generalStyles";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";

const Announcement: FunctionComponent<{
    firstName?: string
}> = ({ firstName }) => {
    const { t } = useTranslation();
    const { classes: generalClasses } = generalUseStyles();
    const isMobile = useMediaQuery("(max-width: 30em)");
    const autoplay = useRef(Autoplay({ delay: 3000 }));

    return (
        <>
            {isMobile ? (
                <>
                    <Alert
                        color="dark.7"
                        bg="gray.3"
                        px="xl"
                        style={{ overflow: "inherit" }}
                    >

                        <Carousel
                            withControls={false}
                            draggable={false}
                            height={35}
                            maw='100%'
                            loop
                            plugins={[autoplay.current]}
                            orientation="vertical"
                        >
                            <Carousel.Slide>
                                <Flex gap='xs' justify='center'>
                                <IconStar size={20} />
                                <Link
                                    to="/contact"
                                    relative="path"
                                    className={generalClasses.link}
                                    style={{
                                        textDecoration: 'underline'
                                    }}
                                >
                                    {t("announcement.msg")}
                                </Link>
                            </Flex>
                            </Carousel.Slide>
                            <Carousel.Slide><Flex gap='xs' justify="center" mb='md'>
                                <IconTruckDelivery size={20} />
                                <Text fw={700}>{t("announcement.msg.delivery")}</Text>
                            </Flex></Carousel.Slide>
                            <Carousel.Slide><Flex gap='xs' justify="center" mb='md'>
                                <IconTruckReturn size={20} />
                                <Text fw={700}>{t("announcement.msg.return")}</Text>
                            </Flex></Carousel.Slide>
                        </Carousel>
                        <Flex align="center" justify="space-between">
                            <Flex align="center">
                                {firstName ? (
                                    <Link
                                        to="/myaccount"
                                        relative="path"
                                        className={generalClasses.link}
                                    >

                                        {t("announcement.hello")} {firstName}!
                                    </Link>
                                ) : (
                                    <>
                                        <Link
                                            to="/register"
                                            relative="path"
                                            className={generalClasses.link}
                                        >
                                            {t("announcement.link.register")}
                                        </Link>
                                        <Divider
                                            mx="xs"
                                            color="primaryColor.0"
                                            orientation="vertical"
                                        />
                                        <Link
                                            to="/login"
                                            relative="path"
                                            className={generalClasses.link}
                                        >
                                            {t("announcement.link.login")}
                                        </Link>
                                    </>
                                )}
                            </Flex>
                            <LanguagePicker />
                        </Flex>
                    </Alert>
                </>
            ) : (
                <Alert
                    color="dark.7"
                    bg="gray.3"
                    px="xl"
                    py={0}
                    style={{ overflow: "inherit" }}
                >
                    <Grid justify="center" align="center" sx={{ height: "100%", maxHeight: "100%", margin: "0", padding: '0' }}>
                        <Grid.Col span={4} h='100%'>
                            <Flex gap='xs'>
                                <IconStar size={20} />
                                <Link
                                    to="/contact"
                                    relative="path"
                                    className={generalClasses.link}
                                    style={{
                                        textDecoration: 'underline'
                                    }}
                                >
                                    {t("announcement.msg")}
                                </Link>
                            </Flex>
                        </Grid.Col>
                        <Grid.Col span={4} h='100%'>
                            <Flex gap='xs' justify='center'>
                                <IconTruckDelivery size={20} />
                                <Text fw={700}>{t("announcement.msg2")}</Text>
                            </Flex>
                        </Grid.Col>
                        <Grid.Col span={4} h='100%'>
                            <Flex align="center" justify='flex-end'>
                                {firstName ? (
                                    <Link
                                        to="/myaccount"
                                        relative="path"
                                        className={generalClasses.link}
                                    >

                                        {t("announcement.hello")} {firstName}!
                                    </Link>
                                ) : (
                                    <>
                                        <Link
                                            to="/register"
                                            relative="path"
                                            className={generalClasses.link}
                                        >
                                            {t("announcement.link.register")}
                                        </Link>
                                        <Divider
                                            mx="xs"
                                            color="primaryColor.0"
                                            orientation="vertical"
                                        />
                                        <Link
                                            to="/login"
                                            relative="path"
                                            className={generalClasses.link}
                                        >
                                            {t("announcement.link.login")}
                                        </Link>
                                    </>
                                )}
                                <Divider mx="xs" color="primaryColor.0" orientation="vertical" />
                                <LanguagePicker />
                            </Flex>
                        </Grid.Col>
                    </Grid>
                </Alert>
            )}
        </>
    );
};

export default Announcement;
