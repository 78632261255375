import { useMutation } from "@tanstack/react-query";
import {graphQLClient, sendResetPasswordEmailQuery} from "../queries";
import { getLanguage } from "../../resources/utils";


export const useSendResetPasswordEmail = (successCallBack: (data: any) => void) => {
    return useMutation(
        ["sendResetPasswordEmailForUser"],//@ts-ignore
        (customerInput: {
            email: string
        }) => {
            const queryVariables: any = customerInput;
            const language = getLanguage();
            return graphQLClient.request(sendResetPasswordEmailQuery(language), queryVariables);
        },
        {
            onSuccess: (data: any) => {
                successCallBack(data);
            },
            onError: (error: any) => {
                console.log(error);
            },
        }
    );
};
