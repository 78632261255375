import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import shoppingCartReducer from "./ShoppingCart/shoppingCartSlice";
import customerReducer from "./Customer/customerSlice";

const rootReducer = combineReducers({
    shoppingCart: shoppingCartReducer,
    customer: customerReducer
})

export const store = configureStore({
    reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
