import { useQuery } from "@tanstack/react-query";
import { getProductsWithFiltersQuery, graphQLClient } from "../queries";
import { IProductsResponse } from "../types";
import { sortKeys } from "../../resources/constants";
import { getLanguage } from "../../resources/utils";

export const useGetProducts = (nrOfProducts: number,
    collection: string,
    filters: any,
    reverse: boolean = false,
    sortKey: string = sortKeys.bestSelling,
    queryKey: string = 'getFilteredProducts',
    successCallback: (data: IProductsResponse) => void = () => null) => {

    let language = getLanguage();
    return useQuery([queryKey], () => graphQLClient.request(getProductsWithFiltersQuery(language), {
        collection: collection,
        first: nrOfProducts,
        filters: filters,
        reverse: reverse,
        sortKey: sortKey
    }), {
        onSuccess: (data: IProductsResponse) => {
            successCallback(data)
            return data
        },
        onError: (err) => {
            console.log(err);
        },
        enabled: false,
        cacheTime: 0
    });
};

