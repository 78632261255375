import {
    Card,
    Image,
    Text,
    Badge,
    Flex,
} from '@mantine/core';
import { FunctionComponent } from 'react';


export const ArticleCard: FunctionComponent<{ imgUrl: string, tags: string[], title: string, body: string }> = (
    { imgUrl,
        tags,
        title,
        body }
) => {

    return (
        <>
            <Card withBorder padding="lg" radius="lg"
                style={{
                    cursor: 'pointer',
                    height: '100%'
                }}
            >
                <Card.Section mb="sm">
                    <Image
                        src={imgUrl}
                        alt={title}
                        height={250}
                        fit='contain'
                    />
                </Card.Section>
                <Flex mt="lg" align='center' direction={'column'}>
                    <Flex gap='xs' w='100%' justify='center'>
                        {tags.map((tag) => (
                            <Badge key={tag} variant="filled">
                                {tag}
                            </Badge>
                        ))}
                    </Flex>

                    <Text fw={700} mt="xs" align='center'>
                        {title}
                    </Text>
                </Flex>
                <Flex mt="lg" align='center' direction={'column'}>
                    <Text fz="xs" c="dimmed" align='center'>
                        {body}
                    </Text>
                    <br />
                    <Text fz="xs" c="dimmed">
                        Share if was useful.
                    </Text>
                </Flex>
            </Card>
        </>
    );
};

export default ArticleCard;