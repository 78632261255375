import React, { FunctionComponent } from "react";
import BaseContainer from "../BaseContainer/BaseContainer";
import { createStyles, List, rem, Text, ThemeIcon, Title, ActionIcon, Flex } from "@mantine/core";
import { IconBrandInstagram, IconBrandTiktok, IconCheck } from "@tabler/icons";
import { Trans, useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `calc(${theme.spacing.xl} * 4)`,
        alignItems: 'center',

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            padding: `calc(${theme.spacing.xl})`,
        },

    },

    content: {
        marginRight: `calc(${theme.spacing.xl} * 3)`,

        [theme.fn.smallerThan('md')]: {
            maxWidth: '100%',
            marginRight: 0,
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            flex: 1,
        },
    },

    image: {
        flex: 1,

        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    highlight: {
        position: 'relative',
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
    },
}));

export const AboutUs: FunctionComponent = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <BaseContainer>
                <div className={classes.inner}>
                    <div className={classes.content}>
                        <Title className={classes.title} align='center'>
                            {t('footer.aboutUs')}
                        </Title>
                        <Title order={4} mt='xl'>
                            {t('aboutUs.p1')} <span className={classes.highlight}>SLWACTIVE</span> <br />
                        </Title>
                        <Text fw={600} fz='lg' mt='md'>
                            {t('aboutUs.p2')}
                            <br /> <br />
                            SLWACTIVE {<Trans
                                i18nKey="aboutUs.p3"
                                components={[<br />]}
                            />}
                            <br /> <br />
                            {<Trans
                                i18nKey="aboutUs.p4"
                                components={[<br />]}
                            />}
                        </Text>
                        <List
                            mt={30}
                            spacing="sm"
                            size="lg"
                            icon={
                                <ThemeIcon size={20} radius="xl">
                                    <IconCheck size={rem(12)} stroke={1.5} />
                                </ThemeIcon>
                            }
                        >
                            <List.Item>
                                {<Trans
                                    i18nKey="aboutUs.p5"
                                    components={[<b />, <br />]}
                                />}
                            </List.Item>
                            <List.Item>
                                {<Trans
                                    i18nKey="aboutUs.p6"
                                    components={[<b />, <br />]}
                                />}
                            </List.Item>
                            <List.Item>
                                {<Trans
                                    i18nKey="aboutUs.p7"
                                    components={[<b />, <br />]}
                                />}
                            </List.Item>
                        </List>
                        <Text fw={600} fz='lg' mt='md'>
                            SLWACTIVE {t('aboutUs.p8')}
                        </Text>
                        <Text fw={600} fz='lg' mt='md' align='center'>
                        {t('aboutUs.p9')}
                        </Text>
                        <Flex justify='center'>
                            <ActionIcon size="lg" onClick={() => window.open('https://www.instagram.com/slwactive', '_blank')}>
                                <IconBrandInstagram size={50} stroke={1.5} color='#cd486b' />
                            </ActionIcon>
                            <ActionIcon size="lg" onClick={() => window.open('https://www.tiktok.com/@slwactive', '_blank')}>
                                <IconBrandTiktok size={50} stroke={1.5} color='#000' />
                            </ActionIcon>
                        </Flex>
                    </div>
                </div>
            </BaseContainer>
        </>
    )
}
