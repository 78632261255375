import { useQuery } from "@tanstack/react-query";
import {getCustomerQuery, graphQLClient} from "../queries";
import {ICustomerDetailsResponse} from "../types";

export const useGetCustomerDetails = (
  accessToken: string,
  successCallBack: (data: ICustomerDetailsResponse) => void
) => {
  return useQuery(
    ["get-details"],
    () => graphQLClient.request(getCustomerQuery(accessToken)),
    {
      onSuccess: (data: ICustomerDetailsResponse) => {
        successCallBack(data);
      },
      onError: (err) => {
        console.log(err);
      },
      enabled: false,
    }
  );
};
