import { useMutation } from "@tanstack/react-query";

export const usePostContactForm = ( successCallBack: (data: any) => void) => {
  return useMutation(
    ["sendContactForm"],
    (contactForm: any) => {
      return fetch('https://slwactive-be.onrender.com/api/v1/contact', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(contactForm)
        })
    },
    {
      onSuccess: (data: any) => {
        successCallBack(data)
      },
      onError: (error: any) => {
        console.log(error)
      }
    }
  );
};
