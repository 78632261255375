
export const pushClickOnProductDataLayer = (productName: string) => {
    //@ts-ignore
    window.dataLayer.push({
        event: 'click_on_product',
        productName: productName
    })
}

export const pushAddToCartDataLayer = (productName: string, size: string, price: string) => {
    //@ts-ignore
    window.dataLayer.push({
        event: 'add_to_cart',
        productName: productName,
        size: size,
        price: price
    })
}

export const pushCheckSizeGuideDataLayer = (productName: string) => {
    //@ts-ignore
    window.dataLayer.push({
        event: 'check_size_guide',
        productName: productName,
    })
}

export const pushClickOnCategoryDataLayer = (category: string) => {
    //@ts-ignore
    window.dataLayer.push({
        event: 'click_on_category',
        category: category,
    })
}


export const pushClickOnCheckoutDataLayer = (totalPrice: number) => {
    //@ts-ignore
    window.dataLayer.push({
        event: 'go_to_checkout',
        cartValue: totalPrice,
    })
}