import { useMutation } from "@tanstack/react-query";
import {createCustomerQuery, graphQLClient} from "../queries";
import {ICustomerCreateInput} from "../types";
import { getLanguage } from "../../resources/utils";

export const useCreateCustomer = ( successCallBack: (data: any) => void) => {
  return useMutation(
    ["createCustomer"],
    (customerInput: ICustomerCreateInput) => {
      const queryVariables: any = customerInput;
      let language = getLanguage();
      return graphQLClient.request(createCustomerQuery(language),queryVariables)
    },
    {
      onSuccess: (data: any) => {
        successCallBack(data)
      },
      onError: (error: any) => {
        console.log(error)
      }
    }
  );
};
