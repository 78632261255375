import React, {FunctionComponent} from 'react';
import {Button, Flex, Grid, Input, Modal, Text} from "@mantine/core";
import _ from "lodash";
import {useStyles} from "../utils/generalStyles";
import {useForm} from "@mantine/form";
import {useTranslation} from "react-i18next";
import {useSendResetPasswordEmail} from "../utils/api/customers/useSendResetPasswordEmail";
import {notifications} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons";
import { validEmailRegex } from '../utils/constants';

const ResetPasswordModal: FunctionComponent<{
    opened: boolean,
    onClose: () => void
}> = ({
          opened,
          onClose
      }) => {
    const {classes} = useStyles ();
    const {t} = useTranslation ()
    const resetPasswordForm = useForm ( {
        initialValues: {
            email: ''
        },
        validate: {
            email: (value: string) => (validEmailRegex.test ( value ) ? null : t ( "validation.email" )),
        }
    } );

    const successResetPassword = (data: any) => {
        if (data.customerRecover.customerUserErrors.length) {
            notifications.show ( {
                icon: <IconX size='1.1rem'/>,
                title: t ( 'notification.error' ),
                color: 'red',
                message: data.customerRecover.customerUserErrors[0].message
            } )
        } else {
            onClose ();
            notifications.show ( {
                icon: <IconCheck size='1.1rem'/>,
                title: t ( 'notification.success' ),
                color: 'green',
                message: t('notification.resetPassword.success'),
            } )
            resetPasswordForm.reset ();
        }
    }

    const {mutate: mutateResetPassowrd, isLoading} = useSendResetPasswordEmail ( successResetPassword );

    const onSubmitForm = (values: {
        email: string
    }) => {
        mutateResetPassowrd ( values )
    }

    return (
        <Modal
            size='xs'
            opened={opened}
            onClose={() => {
                resetPasswordForm.reset ();
                onClose ()
            }}
            classNames={{
                inner: classes.modalBodyWithoutPadding
            }}
            title={<Text fz='lg' fw='bold'>  {t ( 'login.resetPassword' )}</Text>}
            id={_.uniqueId ()}
        >
            <form
                onSubmit={resetPasswordForm.onSubmit ( (values) => onSubmitForm ( values ) )}
            >
                <Grid>
                    <Grid.Col xs={12}>
                        <Input.Wrapper label={t ( 'form.email' )} error={resetPasswordForm.errors.email}>
                            <Input placeholder="you@slwactive.com"
                                   radius="md" {...resetPasswordForm.getInputProps ( "email" )} />
                        </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col xs={12}>
                        <Flex justify='center'>
                            <Button size="sm" radius="xl" mt='xl' type="submit" loading={isLoading}>
                                {t ( 'login.resetPassword' )}
                            </Button>
                        </Flex>
                    </Grid.Col>
                </Grid>
            </form>
        </Modal>
    );
}

export default ResetPasswordModal;
