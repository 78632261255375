import {createStyles, Card, Image, Text, Flex, Button} from '@mantine/core';
import React, {FunctionComponent} from "react";
import {IOrder} from "../utils/api/types";
import {Carousel} from "@mantine/carousel";
import {uniqueId} from "lodash";
import {ReturnProductModal} from "./ReturnProductModal";
import {useDisclosure, useMediaQuery} from "@mantine/hooks";
import { useTranslation } from 'react-i18next';

const useStyles = createStyles ( (theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        [theme.fn.smallerThan ( 'xs' )]: {
           border: 'none !important'
        },
    },

    cardContent: {
        [theme.fn.smallerThan ( 'xs' )]: {
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing.md
        },
    },

    title: {
        fontWeight: 700,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        lineHeight: 1.2,
    },
}) );


export const FinishedOrderCardV2: FunctionComponent<{
    order: IOrder
}> = ({order}) => {
    const {classes} = useStyles ();
    const [openedReturnProductModal, {
        open: openReturnProductModal,
        close: closeReturnProductModal
    }] = useDisclosure ( false );
    const isMobile = useMediaQuery ( "(max-width: 30em)" );
    const {t} = useTranslation();

    return (
        <>
            <Card withBorder radius="md" p={0} className={classes.card} key={uniqueId ()}>
                <Flex className={classes.cardContent}>
                    <Carousel maw={150} withIndicators height={200} >
                        {order.lineItems.nodes.map ( (prod) =>
                            <Carousel.Slide key={uniqueId ()}>
                                <Image key={uniqueId ()} fit='contain' src={prod.variant.image.src}
                                       alt={prod.variant.image.altText} height={200} width='auto' maw={150}/>
                            </Carousel.Slide> )}
                    </Carousel>
                    {!isMobile ?
                        <Flex w='100%' direction={"column"} p='xl' justify={"space-between"}>
                            <Flex w='100%' justify='space-between'>
                                <Text transform="uppercase" color="dimmed" weight={700} size="xs">
                                    {t('myAccount.myOrders.order')} {order.orderNumber}
                                </Text>
                                <Text transform="uppercase" color="dimmed" weight={700} size="xs">
                                    {order.fulfillmentStatus}
                                </Text>
                            </Flex>
                            <Flex w='100%' justify='space-between'>
                                <Text weight={700} size="md" align='center'>
                                {t('myAccount.myOrders.productsNr')} <br/> {order.lineItems.nodes.length}
                                </Text>

                                <Text weight={700} size="md" align='center'>
                                {t('myAccount.myOrders.orderMadeIn')} <br/> {new Date ( order.processedAt ).toLocaleDateString ()}
                                </Text>
                            </Flex>
                            <Flex w='100%' justify='space-between' align='flex-end'>
                                <Text weight={700} size="md" align='center'>
                                {t('myAccount.myOrders.total')} <br/> {order.totalPrice.amount} {order.totalPrice.currencyCode}
                                </Text>
                                <Button color='brand.7' radius='xl' onClick={openReturnProductModal}>
                                {t('myAccount.myOrders.return')}
                                </Button>
                            </Flex>
                        </Flex>
                        :
                        <Flex w='100%' direction={"column"} justify='center' align={'center'}>
                            <Text align={'center'} transform="uppercase" color="dimmed" weight={700} size="xs">
                                Comanda {order.orderNumber}
                            </Text>

                            <Text weight={700} size="md" align='center'>
                            {t('myAccount.myOrders.orderMadeIn')}  <br/> {new Date ( order.processedAt ).toLocaleDateString ()}
                            </Text>
                            <Text weight={700} size="md" align='center'>
                            {t('myAccount.myOrders.total')} <br/> {order.totalPrice.amount} {order.totalPrice.currencyCode}
                            </Text>
                            <Button color='brand.7' radius='xl' onClick={openReturnProductModal}>
                            {t('myAccount.myOrders.return')}
                            </Button>

                        </Flex>
                    }

                </Flex>
            </Card>
            <ReturnProductModal opened={openedReturnProductModal} order={order} close={closeReturnProductModal}
                                refetchCustomerData={() => null}/>
        </>
    );
}
