import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
        link: {
                textDecoration: 'none',
                color: theme.colors.dark[8],
                fontWeight: 700,
                '&:hover': {
                        textDecoration: "underline"
                }
        },
        hiddenMobile: {
                [theme.fn.smallerThan("sm")]: {
                        display: "none",
                },
        },

        hiddenDesktop: {
                [theme.fn.largerThan("sm")]: {
                        display: "none",
                },
        },
        modalBodyWithoutPadding: {
                paddingLeft: 0,
        }
}));
