import {AddressInput} from "../utils/api/types";
import { phoneNumberRegex, validStringRegex } from "../utils/constants";

export interface IUserDetails {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
}

export const userDetailsFormInitialValues: IUserDetails = {
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
};

export const userDetailsFormValidations = (t: any) => {
    return {
        email: (value: string) => (/^\S+@\S+$/.test(value) ? null : t("validation.email")),
        firstName: (value: string) =>
            validStringRegex.test(value)
                ? null
                : t('validation.firstName'),
        lastName: (value: string) =>
            validStringRegex.test(value)
                ? null
                : t('validation.lastName'),
        phone: (value: string) =>
            phoneNumberRegex.test(value) ? null : t('validation.phoneNumber'),
    }
}

export const addressFormInitialValues: AddressInput = {
    address1: "",
    address2: "",
    city: "",
    company: "",
    country: "",
    firstName: "",
    lastName: "",
    phone: "",
    province: "",
    zip: ""
};

export const addressFormValidations = (t: any) => {
    return {
        address1: (value: string) =>
            value.length > 4
                ? null
                : t('validation.address1'),
        province: (value: string) =>
            validStringRegex.test ( value )
                ? null
                : t('validation.province'),
        city: (value: string) =>
           validStringRegex.test ( value )
                ? null
                : t('validation.city'),
        zip: (value: string) =>
            /[0-9]{6,}/.test ( value )
                ? null
                : t('validation.zipCode'),
        firstName: (value: string) =>
            validStringRegex.test ( value )
                ? null
                : t('validation.firstName'),
        lastName: (value: string) =>
            validStringRegex.test ( value )
                ? null
                : t('validation.lastName'),
        phone: (value: string) =>
            phoneNumberRegex.test ( value ) ? null : t('validation.phoneNumber'),
    }
};
