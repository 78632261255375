import {useQuery} from "@tanstack/react-query";
import {getProductDetailsQuery, graphQLClient} from "../queries";
import {IProductDetailsResponse} from "../types";
import { getLanguage } from "../../resources/utils";

export const useGetProductDetails = (id: string, successCallBack: (data: IProductDetailsResponse) => void) => {
    let language = getLanguage();
    return useQuery ( ['getProdDetails', id], async () => await graphQLClient.request ( getProductDetailsQuery(language), {
        id: id
    } ), {
        onSuccess: (data: IProductDetailsResponse) => {
            successCallBack(data)
            return data
        },
        onError: (err) => {
            console.log ( err );
        },
        enabled: false
    } );
};

