import { useEffect, useState } from "react";
import BaseContainer from "../BaseContainer/BaseContainer";
import { ArticleEdge, useGetArticles } from "../utils/api/articles/useGetArticles";
import { ArticleCard } from "./ArticleCard";
import { Grid, Loader, Modal, ScrollArea, Title, TypographyStylesProvider } from "@mantine/core";
import _ from "lodash";
import { useDisclosure } from "@mantine/hooks";
import { useStyles } from "../utils/generalStyles";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


export function Articles() {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const successCallBack = (data: any) => {
        return null;
    }
    const {data, refetch, isLoading} = useGetArticles(successCallBack)
    const [opened, { open, close }] = useDisclosure(false);
    const [articleClicked,setArticleClicked] = useState<number>(0)

    useEffect(() => {
      refetch()
    }, [i18next.language])
    
    return (
        <BaseContainer>
            <Title m="xl" order={3} color="dark" align='center'>
                    {t('blog.title')}
                </Title>
           <Grid m='xl'>
            {isLoading && <Loader />}
            {data && data.articles.edges.map((article: ArticleEdge, key: number) => (
                <Grid.Col xs={12} sm={6} md={4} lg={3} key={_.uniqueId()}
                    onClick={() => {
                        setArticleClicked(key);
                        open();
                    }}
                >
                    <ArticleCard 
                        imgUrl={article.node.image.url} 
                        tags={article.node.tags} 
                        title={article.node.title} 
                        body={article.node.excerpt} 
                    />
                </Grid.Col>
            ))}
           </Grid>
            <Modal 
                opened={opened} 
                onClose={close} centered
                classNames={{
                    inner: classes.modalBodyWithoutPadding
                }}
                scrollAreaComponent={ScrollArea.Autosize}
                size='xl'
            >
                <Title order={2} px='lg' align='center'>
                    {data?.articles.edges[articleClicked].node.title as string}
                </Title>
                    <TypographyStylesProvider>
                        <div dangerouslySetInnerHTML={{ __html: data?.articles.edges[articleClicked].node.contentHtml as string }} />
                    </TypographyStylesProvider>
            </Modal>
        </BaseContainer>
    );
}
