import { Modal, Text, createStyles, Table, Image } from '@mantine/core';
import { FunctionComponent } from "react";
import { useStyles as useGeneralStyles } from "../utils/generalStyles";
import { useMediaQuery } from '@mantine/hooks';
import sizeMenImg from "../utils/resources/sizeMen.jpeg";
import sizeWomenImg from "../utils/resources/sizeWomen.webp";
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({
    title: {
        fontWeight: 600,
        fontSize: theme.fontSizes.xl,
    },
}));

export const SizeGuideModal: FunctionComponent<{
    opened: boolean,
    onClose: () => void,
    productTags?: string[]
}> = ({ opened, onClose, productTags }) => {
    const { classes } = useStyles();
    const { classes: generalClasses } = useGeneralStyles();
    const isMobile = useMediaQuery("(max-width: 30em)");
    const isMen = productTags?.includes('men');
    const {t} = useTranslation();
    //sizes according with carpatree size guides
    const sizes = {
        'men': {
            tankTops: [
                { size: 'S', chest: '99', waist: '80' },
                { size: 'M', chest: '104', waist: '85' },
                { size: 'L', chest: '109', waist: '90' },
                { size: 'XL', chest: '114', waist: '95' },
            ],
            shorts: [
                { size: 'S', waist: '80', insideLeg: 82 },
                { size: 'M', waist: '85', insideLeg: 82 },
                { size: 'L', waist: '90', insideLeg: 82 },
                { size: 'XL', waist: '95', insideLeg: 82 },
            ]
        },
        'women': {
            bra: [
                { size: 'S', bust: '89-92', underbust: '68-82' },
                { size: 'M', bust: '92-99', underbust: '82-92' },
                { size: 'L', bust: '99-107', underbust: '92-97' },
            ],
            leggins: [
                { size: 'S', waist: '71', insideLeg: 77.75 },
                { size: 'M', waist: '76', insideLeg: 77.75 },
                { size: 'L', waist: '81', insideLeg: 77.75 },
            ]
        }
    }

    const MenTable = () => {
        const tankTopsRows = sizes.men.tankTops.map((element, index) => (
            <tr key={element.size + index}>
                <td>{element.size}</td>
                <td>{element.chest}</td>
                <td>{element.waist}</td>
            </tr>
        ));

        const shortsRows = sizes.men.shorts.map((element, index) => (
            <tr key={element.size + index}>
                <td>{element.size}</td>
                <td>{element.waist}</td>
                <td>{element.insideLeg}</td>
            </tr>
        ));

        return (<>
            <Text align='center' my='xl' fw={500}>
                {t('sizeModal.men.msg')}
            </Text>
            <Text align='center' fw={600}>
                {t('sizeModal.men.tshirt')}
            </Text>
            <Table>
                <thead>
                    <tr>
                        <th>{t('sizeModal.size')}</th>
                        <th>{t('sizeModal.bust')}</th>
                        <th>{t('sizeModal.waist')}</th>
                    </tr>
                </thead>
                <tbody>{tankTopsRows}</tbody>
            </Table>
            <br/>
            <Text align='center' fw={600}>
            {t('sizeModal.men.shorts')}
            </Text>
            <Table>
                <thead>
                    <tr>
                        <th>{t('sizeModal.size')}</th>
                        <th>{t('sizeModal.waist')}</th>
                        <th>{t('sizeModal.insideLeg')}</th>
                    </tr>
                </thead>
                <tbody>{shortsRows}</tbody>
            </Table>
            <br/>
            <Text align='center' fw={600}>
              {t('sizeModal.guide')}
            </Text>
            <Image maw='auto' mx="auto" radius="md" src={sizeMenImg} alt="Size men image" />
        </>
        )
    }

    const WomenTable = () => {
        const braRows = sizes.women.bra.map((element, index) => (
            <tr key={element.size + index}>
                <td>{element.size}</td>
                <td>{element.bust}</td>
                <td>{element.underbust}</td>
            </tr>
        ));

        const legginsRows = sizes.women.leggins.map((element, index) => (
            <tr key={element.size + index}>
                <td>{element.size}</td>
                <td>{element.waist}</td>
                <td>{element.insideLeg}</td>
            </tr>
        ));

        return (<>
            <Text align='center' fw={600}>
            {t('sizeModal.women.bra')}
            </Text>
            <Table>
                <thead>
                    <tr>
                    <th>{t('sizeModal.size')}</th>
                        <th>{t('sizeModal.bust')}</th>
                        <th>{t('sizeModal.underBust')}</th>
                    </tr>
                </thead>
                <tbody>{braRows}</tbody>
            </Table>
            <br/>
            <Text align='center' fw={600}>
            {t('sizeModal.women.leggings')}
            </Text>
            <Table>
                <thead>
                    <tr>
                        <th>{t('sizeModal.size')}</th>
                        <th>{t('sizeModal.waist')}</th>
                        <th>{t('sizeModal.insideLeg')}</th>
                    </tr>
                </thead>
                <tbody>{legginsRows}</tbody>
            </Table>
            <br/>
            <Text align='center' fw={600}>
            {t('sizeModal.guide')}
            </Text>
            <Image maw='auto' mx="auto" radius="md" src={sizeWomenImg} alt="Size women image" />
        </>
        )
    }

    return (
        <>
            <Modal
                size={isMobile ? 'sm' : 'lg'}
                opened={opened}
                onClose={onClose}
                title={isMen ? t('sizeModal.men.title') : t('sizeModal.women.title')}
                classNames={{
                    title: classes.title,
                    inner: generalClasses.modalBodyWithoutPadding
                }}>
                   {isMen ? <MenTable /> : <WomenTable />}
            </Modal>
        </>
    );
}
