import { useMutation } from "@tanstack/react-query";
import {graphQLClient, updateCustomerAddressQuery} from "../queries";
import { ICreateAddressInput} from "../types";
import { getLanguage } from "../../resources/utils";

export const useUpdateCustomerAddress = ( successCallBack: (data: any) => void) => {
    return useMutation(
        ["createCustomer"],
        (customerInput: ICreateAddressInput) => {
            const queryVariables: any = customerInput;
            queryVariables.customerAccessToken = localStorage.getItem('accessToken') || '';
            const language = getLanguage();
            return graphQLClient.request(updateCustomerAddressQuery(language),queryVariables)
        },
        {
            onSuccess: (data: any) => {
                successCallBack(data)
            },
            onError: (error: any) => {
                console.log(error)
            }
        }
    );
};
