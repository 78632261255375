import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import _ from "lodash";
import {calculateTotalPrice} from "../../resources/utils";

export interface CartProduct {
    prodUuid: string,
    quantity: number,
    variantId: string,
    merchandiseId: string,
    title: string,
    price: string,
    currency: string,
    image: string,
    size: string
}

const initialState: {
    cartItems: CartProduct[],
    transportPrice: number,
    totalPrice: number,
} = {
    cartItems: [],
    transportPrice: 0,
    totalPrice: 0
}

export const shoppingCartSlice = createSlice({
    name: 'shoppingCartSlice',
    initialState: initialState,
    reducers: {
        addInCart: (state, action: PayloadAction<CartProduct>) => {
            state.cartItems.push(action.payload)
            state.totalPrice = calculateTotalPrice(state.transportPrice, state);
        },
        resetCart: (state, action: PayloadAction<any>) => {
            state.cartItems = [];
            state.totalPrice = calculateTotalPrice(state.transportPrice, state);
        },
        increaseQuantityOfProduct: (state, action: PayloadAction<{
            prodUuid: string
        }>) => {
            const prodUuid = action.payload.prodUuid;
            const indexForReplace: number = _.findIndex(state.cartItems, (elm: CartProduct) => elm.prodUuid === prodUuid);
            const updatedState = _.cloneDeep(state.cartItems);
            updatedState[indexForReplace].quantity = updatedState[indexForReplace].quantity + 1;
            state.cartItems = updatedState;
            state.totalPrice = calculateTotalPrice(state.transportPrice, state);
        },
        decreaseQuantityOfProduct: (state, action: PayloadAction<{
            prodUuid: string
        }>) => {
            const prodUuid = action.payload.prodUuid;
            const indexForReplace: number = _.findIndex(state.cartItems, (elm: CartProduct) => elm.prodUuid === prodUuid);
            const updatedState = _.cloneDeep(state.cartItems);
            updatedState[indexForReplace].quantity = updatedState[indexForReplace].quantity - 1;
            if (updatedState[indexForReplace].quantity <= 0) {
                updatedState.splice(indexForReplace, 1);
            }
            state.cartItems = updatedState;
            state.totalPrice = calculateTotalPrice(state.transportPrice, state);
        }
    },
})
export const {
    addInCart,
    resetCart,
    increaseQuantityOfProduct,
    decreaseQuantityOfProduct
} = shoppingCartSlice.actions

export default shoppingCartSlice.reducer;
