import { useMutation } from "@tanstack/react-query";
import {graphQLClient, loginCustomerQuery} from "../queries";
import {ICustomerLoginInput, ILoginDataResponse} from "../types";
import { getLanguage } from "../../resources/utils";


export const useLoginCustomer = (successCallBack: (data: any) => void) => {
  return useMutation(
    ["loginCustomer"],//@ts-ignore
    (customerInput: ICustomerLoginInput) => {
      const queryVariables: any = customerInput;
      const language = getLanguage();
      return graphQLClient.request(loginCustomerQuery(language), queryVariables);
    },
    {
      onSuccess: (data: ILoginDataResponse) => {
        successCallBack(data);
      },
      onError: (error: any) => {
        console.log(error);
      },
    }
  );
};
