import {
    Header,
    Button,
    Burger,
    ActionIcon,
    Grid,
    Image,
    createStyles, Indicator, Center, Flex,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
    IconShoppingCart, IconUser,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import logo from "../utils/resources/logo2.svg";
import { MobileMenu } from "./MobileMenu/MobileMenu";
import { ShoppingCart } from "./ShoppingCart/ShoppingCart";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../utils/redux/store";
import { pushClickOnCategoryDataLayer } from "../utils/functions";

const useStyles = createStyles((theme) => ({
    centerFlex: {
        display: "flex",
        alignItems: "center"
    },
}));

export const StoreHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [mobileMenuOpened, { toggle: toggleMobileMenu, close: closeMobileMenu }] =
        useDisclosure(false);
    const [shoppingCartOpened, { toggle: toggleShoppingCart, close: closeShoppingCart }] =
        useDisclosure(false);
    const isMobile = useMediaQuery("(max-width: 30em)");
    const { classes } = useStyles();
    const { cartItems } = useSelector(
        (state: RootState) => state.shoppingCart,
    )

    const onAccountClick = () => {
        const isLogged: string | null = localStorage.getItem("isLogged");
        if (isLogged === 'true') {
            navigate('/myaccount')
        } else {
            navigate('/login')
        }
    }

    return (
        <>
            <Header height={60} px="xl" zIndex={12} id='realHeader'>
                {isMobile ? (
                    <Grid justify='center' align={'center'}>
                        <Grid.Col span={4}>
                            <Burger opened={mobileMenuOpened} onClick={toggleMobileMenu} />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Center>
                                <Image onClick={() => navigate('/')} height={55} width="auto" src={logo} alt="logo"
                                    sx={{ cursor: 'pointer' }} />
                            </Center>
                        </Grid.Col>

                        <Grid.Col span={4}>
                            <Flex justify={"flex-end"} gap='xs'>
                                <ActionIcon onClick={onAccountClick} variant="transparent" color="dark.7">
                                    <IconUser size={30} />
                                </ActionIcon>
                                <Indicator inline size={cartItems.length > 0 ? 13 : 0} label={cartItems.length}
                                    position='bottom-end'>
                                    <ActionIcon variant="transparent" color="dark.7">
                                        <IconShoppingCart size={30} onClick={toggleShoppingCart} />
                                    </ActionIcon>
                                </Indicator>
                            </Flex>
                        </Grid.Col>
                    </Grid>
                ) : (
                    <Grid justify="center" align="center" sx={{ height: "100%", maxHeight: "100%", margin: "0" }}>
                        <Grid.Col span={4} h="100%" className={classes.centerFlex}>
                            <Image onClick={() => navigate('/')} height={70} width="auto" src={logo} alt="logo"
                                sx={{ cursor: 'pointer' }} />
                        </Grid.Col>
                        <Grid.Col span={4} h="100%" className={classes.centerFlex} 
                        sx={{
                            placeContent: 'center'
                        }}
                        >
                            <Button variant="subtle" color="dark.7"
                                onClick={() => {
                                    pushClickOnCategoryDataLayer('new');
                                    navigate("/products?type=new")
                                }}
                            >
                                {t("header.btn.newest")}
                            </Button>
                            <Button variant="subtle" color="dark.7"
                                onClick={() => {
                                    pushClickOnCategoryDataLayer('men');
                                    navigate("/products?type=men")
                                }}
                            >
                                {t("header.btn.men")}
                            </Button>
                            <Button variant="subtle" color="dark.7"
                                onClick={() => {
                                    pushClickOnCategoryDataLayer('women');
                                    navigate("/products?type=women")
                                }}
                            >
                                {t("header.btn.women")}
                            </Button>
                            <Button variant="subtle" color="red"
                                onClick={() => {
                                    pushClickOnCategoryDataLayer('sales');
                                    navigate("/products?type=sales")
                                }}
                            >
                                {t("header.btn.sales")}
                            </Button>
                            <Button variant="subtle" color="dark.7"
                                onClick={() => {
                                    pushClickOnCategoryDataLayer('articles');
                                    navigate("/articles")
                                }}
                            >
                                {t("header.btn.blog")}
                            </Button>
                        </Grid.Col>
                        <Grid.Col
                            span={4}
                            h="100%"
                            className={classes.centerFlex}
                            sx={{ justifyContent: "flex-end" }}
                        >
                            <ActionIcon onClick={onAccountClick} mx='md' variant="transparent" color="dark.7">
                                <IconUser size={24} />
                            </ActionIcon>
                            <Indicator inline size={cartItems.length > 0 ? 13 : 0} label={cartItems.length}
                                position='bottom-end'>
                                <ActionIcon variant="transparent" color="dark.7">
                                    <IconShoppingCart size={24} onClick={toggleShoppingCart} />
                                </ActionIcon>
                            </Indicator>
                        </Grid.Col>
                    </Grid>
                )}
            </Header>
            <MobileMenu drawerOpened={mobileMenuOpened} closeDrawer={closeMobileMenu} />
            <ShoppingCart shoppingCartOpened={shoppingCartOpened} closeShoppingCart={closeShoppingCart} />
        </>
    );
};
