import React, {FunctionComponent, useEffect} from "react";
import BaseContainer from "../BaseContainer/BaseContainer";
import {Title, Loader, Grid, Center, Modal, Text} from "@mantine/core";
import {Trans, useTranslation} from "react-i18next";
import {Carousel} from "@mantine/carousel";
import HeroImageBackgroundWithButtons from "../HeroImgBackgroundWithButtons/HeroImageBackgroundWithButtons";
import ProductCardSimple from "../ProductCardSimple/ProductCardSimple";
import {CategorySection} from "../CategoriesSection/CategorySection";
import {useGetProducts} from "../utils/api/products/useGetProducts";
import {useDisclosure, useMediaQuery} from "@mantine/hooks";
import {useNavigate} from "react-router-dom";
import {sortKeys} from "../utils/resources/constants";
import _ from "lodash";
import HeroImageBackgroundSimple from "../HeroImgBackgroundSimple/HeroImageBackgroundSimple";
import i18next from "i18next";
import { pushClickOnProductDataLayer } from "../utils/functions";
import { useStyles } from "../utils/generalStyles";

const Homepage: FunctionComponent = () => {
    const {t} = useTranslation ();
    const navigate = useNavigate ()
    const {data, isLoading, refetch} = useGetProducts (
        10,
        "men",
        [],
        false,
        sortKeys.bestSelling,
        'getTrendingProducts'
    );
    const {
        data: dataTrending,
        isLoading: isLoadingTreding,
        refetch: refetchTrending,
    } = useGetProducts ( 4, "all", [], false, sortKeys.created, 'getBeDiffrentData' );
    const [opened, { open, close }] = useDisclosure(false);
    const {classes} = useStyles();
    const isOnMobile = useMediaQuery ( "(max-width: 36em)" );
    const isUnderLaptopXS = useMediaQuery ( "(max-width: 64em)" );
    let slideSize: string = '25%';
    if (isUnderLaptopXS) {
        slideSize = '50%';
    }
    if (isOnMobile) {
        slideSize = '100%'
    }


    useEffect ( () => {
        refetch ().then ( () => refetchTrending () );
    }, [i18next.language] );

    const windowHref = window.location.href;
    useEffect(() => {
        if(windowHref.includes("?easteregg")) {
           open();
        }
    }, [])
    

    return ( <>
        <BaseContainer>
            <HeroImageBackgroundWithButtons/>
            <Title m="xl" order={2} color="dark">
                {t ( "homepage.title" )}
            </Title>
            {isLoading && !data ? (
                <Center>
                    <Loader color='gray'/>
                </Center>
            ) : (
                <Carousel
                    slideSize={slideSize}
                    slideGap="xs"
                    controlsOffset="xs"
                    controlSize={40}
                    loop
                    align='start'
                    sx={(theme) => ({
                        margin: theme.spacing.xl,
                    })}
                >
                    {data &&
                        data.collection.products.edges.map ( (product) => (
                            <Carousel.Slide>
                                <ProductCardSimple
                                    key={_.uniqueId ()}
                                    imgUrl={product.node.featuredImage.src}
                                    title={product.node.title}
                                    price={product.node.priceRange.maxVariantPrice.amount}
                                    tags={product.node.tags}
                                    currencyCode={
                                        product.node.priceRange.maxVariantPrice.currencyCode
                                    }
                                    onClick={() => {
                                        pushClickOnProductDataLayer(product.node.title);
                                        navigate ( `/product/${product.node.id.split ( "Product/" )[1]}` )
                                    }}
                                />
                            </Carousel.Slide>
                        ) )}
                </Carousel>
            )}
            <CategorySection/>
            <Title m="xl" order={2} color="dark">
                {t ( "homepage.title2" )}
            </Title>
            {isLoadingTreding && !dataTrending ? (
                <Center>
                    <Loader color='gray'/>
                </Center>
            ) : (
                <Grid mx="sm" gutter={50}>
                    {dataTrending &&
                        dataTrending.collection.products.edges.map ( (product) => (
                            <Grid.Col xs={12} sm={6} md={6} lg={3} key={_.uniqueId ()}>
                                <ProductCardSimple
                                    imgUrl={product.node.featuredImage.src }
                                    title={product.node.title}
                                    price={product.node.priceRange.maxVariantPrice.amount}
                                    tags={product.node.tags}
                                    currencyCode={
                                        product.node.priceRange.maxVariantPrice.currencyCode
                                    }
                                    onClick={() => {
                                        pushClickOnProductDataLayer(product.node.title);
                                        navigate ( `/product/${product.node.id.split ( "Product/" )[1]}` )
                                    }}
                                />
                            </Grid.Col>
                        ) )}
                </Grid>
            )}
            <HeroImageBackgroundSimple/>
            <Title m="xl" order={2} color="dark">
                {t ( "homepage.title3" )}
            </Title>
            {isLoadingTreding && !dataTrending ? (
                <Center>
                    <Loader color='gray'/>
                </Center>
            ) : (
                <Grid mx="sm" gutter={50}>
                    {dataTrending &&
                        dataTrending.collection.products.edges.reverse().map ( (product) => (
                            <Grid.Col xs={12} sm={6} md={6} lg={3} key={_.uniqueId ()}>
                                <ProductCardSimple
                                    imgUrl={product.node.featuredImage.src }
                                    title={product.node.title}
                                    price={product.node.priceRange.maxVariantPrice.amount}
                                    tags={product.node.tags}
                                    currencyCode={
                                        product.node.priceRange.maxVariantPrice.currencyCode
                                    }
                                    onClick={() => {
                                        pushClickOnProductDataLayer(product.node.title);
                                        navigate ( `/product/${product.node.id.split ( "Product/" )[1]}` )
                                    }}
                                />
                            </Grid.Col>
                        ) )}
                </Grid>
            )}
        </BaseContainer>
        <Modal 
                opened={opened} 
                onClose={close} centered
                classNames={{
                    inner: classes.modalBodyWithoutPadding
                }}
            
                size='xl'
            >
                <Title order={2} px='lg' align='center'>
                    {t('easteregg.title')}
                </Title>
                <Text size='xl' m='xl' align='center'>
                {<Trans
                            i18nKey="easteregg.body"
                            components={[<br />, <b />]}
                        />}
                </Text>
            </Modal>
        </>
    );
};

export default Homepage;
