import React, {FunctionComponent} from "react";
import {Card, Text, createStyles, Flex, Image, Badge} from "@mantine/core";
import {useTranslation} from "react-i18next";

export interface ProductCardProps {
    imgUrl: string;
    title: string;
    tags: string[];
    price: string;
    currencyCode: string;
    onClick: () => void;
}

const useStyles = createStyles ( (theme) => ({
    card: {
        width: "100%",
        cursor: "pointer",

        "&:hover": {
            transition: "0.3s all",
            transform: "scale(1.05)"
        },
    },
    title: {
        color: theme.black,
        fontWeight: 700,
    },
    price: {
        color: theme.black,
        fontWeight: 600,
        "& > s": {
            color: theme.colors.red[6],
        },
    },
}) );

const ProductCardSimple: FunctionComponent<ProductCardProps> = ({
                                                                    imgUrl,
                                                                    title,
                                                                    tags,
                                                                    price,
                                                                    currencyCode,
                                                                    onClick
                                                                }) => {
    const {classes} = useStyles ();
    const {t} = useTranslation ();
    const salePrice: number = Number ( price ) + Number ( price ) * 0.3;
    return (
        <Card className={classes.card} onClick={onClick}>
            <Flex h="100%" w="100%" direction="column" justify="space-between">
                <Image src={imgUrl} alt={title + tags[0]} fit="contain"/>
                <Flex direction="row" justify="space-between">
                    <Flex direction="column" align="flex-start">
                        <Text className={classes.title} size="lg">
                            {title}
                        </Text>
                        <Text className={classes.price} size="lg">
                            {price} {currencyCode.toLowerCase ()}
                        </Text>
                    </Flex>
                    <Flex direction="column" align="flex-end" justify='space-evenly'>
                        <>
                            {tags.includes ( "sales" ) &&
                                <>
                                    <Badge color="red" variant="filled">
                                        {t ( "badge.sale" )}
                                    </Badge>
                                    <Text className={classes.price} size='sm'>
                                        <s>{salePrice.toFixed ( 2 )} {currencyCode.toLowerCase ()}</s>
                                    </Text>
                                </>
                            }
                            {tags.includes ( "new" ) &&
                                <Badge color="primary" variant="filled">
                                    {t ( "badge.new" )}
                                </Badge>
                            }
                        </>
                    </Flex>
                </Flex>
                {/*)}*/}
            </Flex>
        </Card>
    );
};

export default ProductCardSimple;
