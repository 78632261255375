import React, { FunctionComponent, useEffect, useState } from "react";
import BaseContainer from "../BaseContainer/BaseContainer";
import {
    Text,
    Title,
    Grid,
    Loader,
    Stack,
    RangeSlider, Button, Center, NativeSelect, Drawer, Affix, rem, Flex
} from "@mantine/core";
import { useGetProducts } from "../utils/api/products/useGetProducts";
import ProductCardSimple from "../ProductCardSimple/ProductCardSimple";
import { useDisclosure, useMediaQuery, useWindowScroll } from "@mantine/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconAdjustmentsHorizontal, IconArrowsSort } from "@tabler/icons";
import { sortKeys } from "../utils/resources/constants";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { IProductsResponse } from "../utils/api/types";
import i18next from "i18next";
import { pushClickOnProductDataLayer } from "../utils/functions";

const ProductsWithFiltersPage: FunctionComponent = () => {
    const [queryParams] = useSearchParams();
    const { t } = useTranslation();
    const type: string | null = queryParams.get("type");
    const [mobileFiltersOpen, { toggle: toggleMobileFilters, close: closeMobileFilters }] =
        useDisclosure(false);
    // eslint-disable-next-line
    const [scroll, scrollTo] = useWindowScroll();
    const [filters, setFilters] = useState<any[]>([]);
    const [sortKey, setSortKey] = useState<string>(sortKeys.bestSelling);
    const [reverseSort, setReverseSort] = useState(false);
    const [selectValue, setSelectValue] = useState('bestsellers')
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width: 30em)");


    const resetFilters = () => {
        setFilters([]);
        setSortKey(sortKeys.bestSelling);
        setReverseSort(false);
        setSelectValue('bestsellers')
        refetch();
    }

    const successCallback = (data: IProductsResponse) => {
        closeMobileFilters();
    }
    const {
        data,
        isRefetching,
        refetch
    } = useGetProducts(12, type === null ? 'all' : type, filters, reverseSort, sortKey, `getFilteredProducts`, successCallback);

    const isSmallerThanTablet = useMediaQuery("(max-width: 62em)");
    //TODO: find a better solution
    useEffect(() => {
        resetFilters()
        scrollTo({ y: 0 })
    }, [type])

    useEffect(() => {
        refetch();
    },[i18next.language])


    const FiltersContent = () => {
        return <>
            <Stack spacing='xs' sx={{
                position: 'sticky',
                top: 0
            }}>
                <Title m="xl" order={3} color="dark" align='center'>
                    {t('filtersAndSort.title')}
                </Title>
                <Text fz='sm' fw={500} align='center'>
                    {t('sort.sortTitle')}
                </Text>
                <NativeSelect
                    value={selectValue}
                    data={[
                        {
                            label: t('sort.bestsellers') as string,
                            value: 'bestsellers'
                        },
                        {
                            label: t('sort.newest') as string,
                            value: 'newest'
                        },
                        {
                            label: t('sort.biggerPrice') as string,
                            value: 'biggerPrice'
                        },
                        {
                            label: t('sort.smallerPrice') as string,
                            value: 'smallerPrice'
                        }
                    ]}
                    icon={<IconArrowsSort color='#626F92' size="1rem" />}
                    onChange={(value) => {
                        setSelectValue(value.currentTarget.value);
                        switch (value.currentTarget.value) {
                            case 'bestsellers':
                                setSortKey(sortKeys.bestSelling);
                                setReverseSort(false)
                                break;
                            case 'newest':
                                setSortKey(sortKeys.created)
                                setReverseSort(true)
                                break;
                            case 'smallerPrice':
                                setSortKey(sortKeys.price)
                                setReverseSort(true)
                                break;
                            case 'biggerPrice':
                                setSortKey(sortKeys.price)
                                setReverseSort(false)
                                break;
                        }
                    }
                    }
                />
                <Text fz='sm' fw={500} align='center'>
                    {t('filter.byPrice')}
                </Text>
                <RangeSlider
                    min={0}
                    max={1000}
                    onChangeEnd={value => {
                        const updatedFilters = filters;
                        const indexForUpdate = updatedFilters.findIndex(e => Object.keys(e).includes('price'))
                        const priceFilter = {
                            price: {
                                min: value[0],
                                max: value[1]
                            }
                        }
                        if (indexForUpdate >= 0) {
                            updatedFilters[indexForUpdate] = priceFilter;
                        } else {
                            updatedFilters.push(priceFilter);
                        }
                        setFilters(updatedFilters)
                    }}
                />
                <Center>
                    <Button variant="subtle" color="dark.7" mt='xl' onClick={() => {
                        refetch()
                    }
                    }>
                        {t('filter.btn.txt')}
                    </Button>
                </Center>
            </Stack>
        </>
    }

    const returnTitle = () => {
        if (type) {
            switch (type) {
                case 'new':
                    return t('title.newestProducts');
                case 'men':
                    return t('title.menClothes');
                case 'women':
                    return t('title.womenClothes');
                case 'sales':
                    return t('title.sales');

            }

        }
    }

    return (
        <BaseContainer>
            <Title order={3} align='center' my='xl'>{returnTitle()}</Title>
            <Grid m="sm">
                {isSmallerThanTablet ?
                    <Affix position={{ bottom: rem(20), left: rem(20) }}>
                        <Button radius='xl' mx='xl' leftIcon={<IconAdjustmentsHorizontal />} onClick={toggleMobileFilters}>
                            {t('filtersAndSort.title')}
                        </Button>
                    </Affix>
                    :
                    <>
                        <Grid.Col span={2}>
                            <FiltersContent />
                        </Grid.Col>
                    </>}
                <Grid.Col xs={12} md={10}>
                    {isRefetching ? (
                        <Center>
                            <Loader color='gray' />
                        </Center>
                    ) : (
                        <Grid gutter={50}>
                            {data?.collection.products.edges.length === 0 ?
                                <>
                                    <Grid.Col md={2} xs={0} />
                                    <Grid.Col md={6} xs={12}>
                                        <Flex direction='column' align='center'>
                                            <Text align='center' fw={500}>{t('products.noProductsByFilters')}</Text>
                                            <Button
                                                w={isMobile ? '100%' : '25%'}
                                                variant="subtle" color="dark.7" mt='xl' onClick={() => {
                                                    resetFilters()
                                                }
                                                }>
                                                {t('filter.btn.reset')}
                                            </Button>
                                        </Flex>
                                    </Grid.Col>
                                    <Grid.Col md={4} xs={0} />
                                </>
                                :
                                data?.collection.products.edges.map((product) => (
                                    <Grid.Col xs={6}
                                        sm={6}
                                        md={6}
                                        lg={4}
                                        key={_.uniqueId()}
                                    >
                                        <ProductCardSimple
                                            key={_.uniqueId()}
                                            imgUrl={product.node.featuredImage.src }
                                            title={product.node.title}
                                            price={product.node.priceRange.maxVariantPrice.amount}
                                            tags={product.node.tags}
                                            currencyCode={
                                                product.node.priceRange.maxVariantPrice.currencyCode
                                            }
                                            onClick={() => {
                                                pushClickOnProductDataLayer(product.node.title);
                                                navigate(`/product/${product.node.id.split("Product/")[1]}`)
                                            }}
                                        />
                                    </Grid.Col>
                                ))
                            }
                        </Grid>
                    )}
                </Grid.Col>
            </Grid>
            <Drawer
                opened={mobileFiltersOpen}
                onClose={closeMobileFilters}
                size="xs"
                padding="xl"
                position="left"
                zIndex={1000000}
            >
                <FiltersContent />
            </Drawer>
        </BaseContainer>
    );
};

export default ProductsWithFiltersPage;
