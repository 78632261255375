import React, {FunctionComponent, useEffect, useState} from "react";
import { Menu, Image, UnstyledButton, createStyles } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import roImg from "./images/ro.png";
import engImg from "./images/uk.png";
import i18n from "i18next";
import _ from "lodash";

const data = [
  { value: "ro", label: "Romanian", image: roImg },
  { value: "en", label: "English", image: engImg },
];

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    width: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background-color 150ms ease",
    backgroundColor: theme.colors.gray[3],
  },
  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

const LanguagePicker: FunctionComponent = () => {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });
  const [selected, setSelected] = useState(data[0]);

  const items = data.map((item) => (
    <Menu.Item
      icon={<Image src={item.image} width={18} height={18} />}
      onClick={() => {
          setSelected(item);
          i18n.changeLanguage(item.value);
      }}
      key={_.uniqueId()}
    />
  ));

    useEffect ( () => {
        const foundData = data.find(e => e.value === i18n.language);
        foundData ? setSelected(foundData) : setSelected(data[1]);
    }, []);


  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Image src={selected.image} alt='language-flag' width={22} height={22} pr="sm" />
          <IconChevronDown size={16} className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown bg="gray.3">{items}</Menu.Dropdown>
    </Menu>
  );
};

export default LanguagePicker;
