import { useMutation } from "@tanstack/react-query";
import {deleteCustomeAddressQuery, graphQLClient} from "../queries";
import { IDeleteAddressInput} from "../types";
import { getLanguage } from "../../resources/utils";

export const useDeleteCustomerAddress = ( successCallBack: (data: any) => void) => {
    return useMutation(
        ["createCustomer"],
        (customerInput: IDeleteAddressInput ) => {
            const queryVariables: any = customerInput;
            queryVariables.customerAccessToken = localStorage.getItem('accessToken') || '';
            const language = getLanguage();
            return graphQLClient.request(deleteCustomeAddressQuery(language),queryVariables)
        },
        {
            onSuccess: (data: any) => {
                successCallBack(data)
            },
            onError: (error: any) => {
                console.log(error)
            }
        }
    );
};
