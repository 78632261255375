import React, {FunctionComponent} from "react";
import {Button, Drawer, Flex} from "@mantine/core";
import {IconChevronRight} from "@tabler/icons";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import { pushClickOnCategoryDataLayer } from "../../utils/functions";

interface IMobileMenuProps {
    drawerOpened: boolean,
    closeDrawer: () => void
}

export const MobileMenu: FunctionComponent<IMobileMenuProps> = (props) => {
    const { t } = useTranslation();
    const {drawerOpened, closeDrawer} = props;
    const navigate = useNavigate()

    return (
        <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="70%"
            padding="xl"
            position="left"
            zIndex={1000000}
        >
            <Flex
                gap="md"
                justify="center"
                align="flex-start"
                direction="column"
                wrap="wrap"
            >
                <Button
                    variant="subtle"
                    w="100%"
                    color="dark.7"
                    styles={() => ({
                        inner: {
                            justifyContent: "space-between",
                        },
                    })}
                    rightIcon={<IconChevronRight size={20} />}
                    onClick={() => {
                        pushClickOnCategoryDataLayer('new');
                        navigate ( "/products?type=new" )
                        closeDrawer()
                    }}
                >
                    {t("header.btn.newest")}
                </Button>
                <Button
                    variant="subtle"
                    w="100%"
                    color="dark.7"
                    styles={() => ({
                        inner: {
                            justifyContent: "space-between",
                        },
                    })}
                    rightIcon={<IconChevronRight size={20} />}
                    onClick={() => {
                        pushClickOnCategoryDataLayer('men');
                        navigate ( "/products?type=men" )
                        closeDrawer()
                    }}
                >
                    {t("header.btn.men")}
                </Button>
                <Button
                    variant="subtle"
                    w="100%"
                    color="dark.7"
                    styles={() => ({
                        inner: {
                            justifyContent: "space-between",
                        },
                    })}
                    rightIcon={<IconChevronRight size={20} />}
                    onClick={() => {
                        pushClickOnCategoryDataLayer('women');
                        navigate ( "/products?type=women" )
                        closeDrawer()
                    }}
                >
                    {t("header.btn.women")}
                </Button>
                <Button
                    variant="subtle"
                    w="100%"
                    color="red"
                    styles={() => ({
                        inner: {
                            justifyContent: "space-between",
                        },
                    })}
                    rightIcon={<IconChevronRight size={20} />}
                    onClick={() => {
                        pushClickOnCategoryDataLayer('sales');
                        navigate ( "/products?type=sales" )
                        closeDrawer()
                    }}
                >
                    {t("header.btn.sales")}
                </Button>
                <Button
                    variant="subtle"
                    w="100%"
                    color="dark.7"
                    styles={() => ({
                        inner: {
                            justifyContent: "space-between",
                        },
                    })}
                    rightIcon={<IconChevronRight size={20} />}
                    onClick={() => {
                        pushClickOnCategoryDataLayer('articles');
                        navigate ( "/articles" )
                        closeDrawer()
                    }}
                >
                    {t("header.btn.blog")}
                </Button>
            </Flex>
        </Drawer>
    )
}
