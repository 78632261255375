import { gql, GraphQLClient } from "graphql-request";

export const graphQLClient = new GraphQLClient(
  `${process.env.REACT_APP_API_URL}`,
  {
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": `${process.env.REACT_APP_ACCESS_TOKEN}`
    },
  }
);


//useGetArticles
export const getArticlesQuery = (lang: string) => {
  return gql`
    query @inContext(language: ${lang}) {
      articles(first: 24) {
        edges {
          node {
            id
            contentHtml
            title
            image {
              url
            }
            tags
            excerpt
          }
        }
      }
    }   
`;
}
//useGetProducts
export const getProductsWithFiltersQuery = (lang: string) => {
  return gql`
    query getProducts($collection: String!,$first: Int,$filters: [ProductFilter!], $sortKey: ProductCollectionSortKeys, $reverse: Boolean) @inContext(language: ${lang}) {
      collection(handle: $collection) {
        handle
        products(filters: $filters, first: $first, sortKey: $sortKey, reverse: $reverse) {
          edges {    
            node {
              id
              title
              featuredImage {
                src
              }
              priceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              tags
              descriptionHtml
              totalInventory
              productType
              createdAt
            }
          }
        }
        descriptionHtml
      }
    }
  `;
}

//useGetProductDetails
export const getProductDetailsQuery = (lang: string) => {
  return gql`
    query getProductDetails($id: ID) @inContext(language: ${lang}) {
     product(id: $id) {
       descriptionHtml
       id
       handle
       featuredImage {
         altText
         src
       }
       images(first: 10) {
         edges {
           node {
             src
             altText
           }
         }
       }
       options {
         id
         name
         values
       }
       priceRange {
         maxVariantPrice {
           currencyCode
           amount
         }
         minVariantPrice {
           currencyCode
           amount
         }
       }
       productType
       title
       totalInventory
       tags
       seo {
         title
         description
       }
       vendor
       variants(first: 5) {
         nodes {
           priceV2 {
             amount
             currencyCode
           }
           id
           title
         }
       }
       media(first: 5) {
         edges {
           node {
             id
             alt
             mediaContentType
             ... on Video {
               id
               mediaContentType
               sources {
                 url
                 format
               }
               previewImage {
                 src
               }
             }
             ... on ExternalVideo {
               id
               embeddedUrl
               embedUrl
             }
             ... on MediaImage {
               id
               image {
                 url
               }
             }
           }
         }
       }
     }
   }`
}

//useCreateCustomer
export const createCustomerQuery = (lang: string) => {
  return gql`
  mutation customerCreate($input: CustomerCreateInput!) @inContext(language: ${lang}) {
    customerCreate(input: $input) {
      customerUserErrors {
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
`; 
}
//useGetCustomerDetails
export const getCustomerQuery = (accessToken: string) => {
  return gql`
  query CustomerDetails {
    customer(customerAccessToken: "${accessToken}") {
        acceptsMarketing
        addresses(first: 4) {
          nodes {
            id
            address1
            address2
            city
            company
            country
            countryCode
            countryCodeV2
            firstName
            lastName
            name
            phone
            provinceCode
            zip
            province
          }
        }
        createdAt
        defaultAddress {
          address1
          city
          address2
          company
          countryCode
          country
          countryCodeV2
          firstName
          lastName
          name
          phone
          province
          provinceCode
          zip
          id
        }
        firstName
        email
        displayName
        lastName
        id
        numberOfOrders
        phone
        orders(first: 6) {
          nodes {
            id
            orderNumber
            name
            email
            processedAt
            financialStatus
            fulfillmentStatus
            totalPrice {
              amount
              currencyCode
            }
            lineItems(first: 10) {
              nodes {
                title
                variant {
                  id
                  selectedOptions {
                    value
                    name
                  }
                  image {
                    src
                    altText
                    id
                    url
                  }
                }
              }
            }
          }
        }
    }
  }
`;
};
//useLoginCustomer
export const loginCustomerQuery = (lang: string) => {
  return gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) @inContext(language: ${lang}) {
    customerAccessTokenCreate(input: $input) {
      customerUserErrors {
        code
        field
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`;
}
//useUpdateCustomer
export const updateCustomerQuery = (lang: string) => {
  return gql`
      mutation customerUpdate($customer: CustomerUpdateInput!, $customerAccessToken: String!) @inContext(language: ${lang}) {
          customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
            customer {
              firstName
              lastName
              email
              phone
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }
`; 
}
//useCreateCustomerAddress
export const createCustomerAddressQuery = (lang: string) => {
  return gql`
    mutation customerAddressCreate($address: MailingAddressInput!, $customerAccessToken: String!) @inContext(language: ${lang}) {
      customerAddressCreate(address: $address, customerAccessToken: $customerAccessToken) {
        customerAddress {
            id
            address1 
            address2 
            city 
            company 
            country 
            countryCode 
            countryCodeV2 
            firstName 
            lastName 
            name 
            phone 
            provinceCode 
            zip 
            province 
        }
        customerUserErrors {
          code
          field 
          message
        }
      }
    }
`; }
//useUpdateCustomerAddress
export const updateCustomerAddressQuery = (lang: string) => {
  return gql`
    mutation customerAddressUpdate($address: MailingAddressInput!, $customerAccessToken: String!,$id: ID!) @inContext(language: ${lang}) {
      customerAddressUpdate(address: $address, customerAccessToken: $customerAccessToken, id: $id) {
        customerAddress {
            id
            address1 
            address2 
            city 
            company 
            country 
            countryCode 
            countryCodeV2 
            firstName 
            lastName 
            name 
            phone 
            provinceCode 
            zip 
            province 
        }
        customerUserErrors {
          code
          field 
          message
        }
      }
    }
`; }
//userDeleteAddress
export const deleteCustomeAddressQuery = (lang: string) => 
  {
    return gql`
      mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) @inContext(language: ${lang}) {
        customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
          customerUserErrors {
            code
            field 
            message
          }
          deletedCustomerAddressId
        }
      }
  `;}
//useCreateCart
export const createCartQuery = (lang: string) =>
{
  return gql`
    mutation cartCreate($input: CartInput) @inContext(language: ${lang}) {
      cartCreate(input: $input) {
        cart {
          checkoutUrl
        }
        userErrors {
          code
          field
          message
        }
      }
    }
`;}
//useResetCustomerPassword
export const sendResetPasswordEmailQuery = (lang: string) => {
  return  gql`
  mutation customerRecover($email: String!) @inContext(language: ${lang}) {
    customerRecover(email: $email) {
       customerUserErrors {
        code
        field 
        message
      }
    }
  }
  `;
}
//useReturnProduct
export const returnProductQuery = gql`
    mutation returnCreate($returnInput: ReturnInput!) {
      returnCreate(returnInput: $returnInput) {
        userErrors {
          field
          message
        }
      }
    }
`
