import {
    Paper,
    Text,
    TextInput,
    Textarea,
    Button,
    Group,
    SimpleGrid,
    createStyles,
    rem, Flex,
} from '@mantine/core';
import { ContactIconsList } from './ContactIcons';
import BaseContainer from "../BaseContainer/BaseContainer";
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { usePostContactForm } from '../utils/api/contact/usePostContactForm';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons';
import { validEmailRegex } from '../utils/constants';

const useStyles = createStyles((theme) => {
    const BREAKPOINT = theme.fn.smallerThan('sm');

    return {
        wrapper: {
            display: 'flex',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
            borderRadius: theme.radius.lg,
            padding: rem(4),
            border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2]
                }`,

            [BREAKPOINT]: {
                flexDirection: 'column',
                alignItems: 'center'
            },
        },

        form: {
            boxSizing: 'border-box',
            flex: 1,
            padding: theme.spacing.xl,
            paddingLeft: `calc(${theme.spacing.xl} * 2)`,
            borderLeft: 0,
            width: '50vh',

            [BREAKPOINT]: {
               textAlign: 'center',
               padding: '0 5rem'
            },
        },

        fields: {
            marginTop: rem(-12),
        },

        fieldInput: {
            flex: 1,

            '& + &': {
                marginLeft: theme.spacing.md,

                [BREAKPOINT]: {
                    marginLeft: 0,
                    marginTop: theme.spacing.md,
                },
            },
        },

        fieldsGroup: {
            display: 'flex',

            [BREAKPOINT]: {
                flexDirection: 'column',
            },
        },

        contacts: {
            boxSizing: 'border-box',
            position: 'relative',
            borderRadius: theme.radius.lg,
            backgroundImage: 'linear-gradient(to right top, #626f92, #6d7a9d, #7986a8, #8491b3, #909dbe)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            border: `${rem(1)} solid transparent`,
            padding: theme.spacing.xl,
            flex: `0 0 ${rem(280)}`,

            [BREAKPOINT]: {
                marginBottom: theme.spacing.sm,
                paddingLeft: theme.spacing.md,
            },
        },

        title: {
            marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,

            [BREAKPOINT]: {
                marginBottom: theme.spacing.xl,
            },
        },

        control: {
            [BREAKPOINT]: {
                flex: 1,
            },
        },
    };
});

export function ContactSection() {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const contactForm = useForm({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: ''
        },

        validate: {
            name: (value) => value?.length > 3 ? null : t('validation.name'),
            email: (value) => validEmailRegex.test(value) ? null : t('validation.email'),
            subject: (value) => value.length > 3 ? null : t('validation.subject'),
            message: (value) => value?.length > 15 ? null : t('validation.message'),
        },
    });

    const successCallBack = (res: any) => {
        contactForm.reset();
        if (res.status === 200) {
            notifications.show({
                icon: <IconCheck size='1.1rem' />,
                title: t('notification.msgSent.title'),
                color: 'green',
                message: t('notification.msgSent.description'),
            })
        }
        else {
            notifications.show({
                icon: <IconX size='1.1rem' />,
                title: t('notification.msgNotSent.title'),
                color: 'red',
                message: t('notification.msgNotSent.description'),
            })
        }
    }
    const { mutate, isLoading } = usePostContactForm(successCallBack);

    const onSend = (values: any) => {
        mutate(values);
    }

    return (
        <BaseContainer>
            <Flex justify='center' my='xl'>
                <Paper shadow="md" radius="lg">
                    <div className={classes.wrapper}>
                        <div className={classes.contacts}>
                            <Text fz="lg" fw={700} className={classes.title} c="#fff">
                                {t('contact.title')}
                            </Text>

                            <ContactIconsList variant="white" />
                        </div>

                        <form className={classes.form} onSubmit={contactForm.onSubmit((values) => onSend(values))}>
                            <Text fz="lg" fw={600} className={classes.title}>

                                {<Trans
                                    i18nKey="contact.form.title"
                                    components={[<br />]}
                                />}
                            </Text>

                            <div className={classes.fields}>
                                <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                                    <TextInput withAsterisk label={t('form.lastName')} placeholder="John Doe"  {...contactForm.getInputProps('name')} />
                                    <TextInput withAsterisk label={t('form.email')} placeholder="you@slwactive.com" {...contactForm.getInputProps('email')} />
                                </SimpleGrid>

                                <TextInput withAsterisk mt="md" label={t('form.subject')} placeholder={t('form.subject') as string} {...contactForm.getInputProps('subject')} />

                                <Textarea
                                    mt="md"
                                    label={t('form.message')}
                                    placeholder={t('form.message') as string}
                                    minRows={3}
                                    withAsterisk
                                    {...contactForm.getInputProps('message')}
                                />

                                <Group position="right" mt="md">
                                    <Button type="submit" className={classes.control} radius='xl' loading={isLoading}>
                                        {t('form.submit')}
                                    </Button>
                                </Group>
                            </div>
                        </form>
                    </div>
                </Paper>
            </Flex>
        </BaseContainer>
    );
}
