export const translations: any = {
    en: {
        translation: {
            "announcement.msg": "Become slwactive`s ambassador!",
            "announcement.msg2": "Free shipping over 199.99 RON & 15 days free return!",
            "announcement.msg.delivery": "Free shipping over 199.99 RON!",
            "announcement.msg.return": "15 days free return!",
            "announcement.link.register": "Join us",
            "announcement.link.login": "Sign in",
            "announcement.hello": "Hello,",
            "header.btn.newest": "Newest",
            "header.btn.men": "Men",
            "header.btn.women": "Women",
            "header.btn.sales": "Sales",
            "header.btn.blog": "Blog",
            "blog.title": "slwactive's blog",
            "category.men": "Men",
            "category.women": "Women",
            "btn.shopNow": "Shop now",
            "badge.new": "New",
            "badge.sale": "Sale",
            "homepage.title": "Trending this week",
            'homepage.title2': "Be different",
            'homepage.title3': "Best choices",
            "header.shoppingCart.title": "Your shopping cart",
            "shoppingCart.emptyMsg": "Your shopping cart is empty!",
            "shoppingCart.shopMen": "Shop men",
            "shoppingCart.shopWomen": "Shop women",
            "shoppingCart.redirectToCheckout": "You'll be redirected to the checkout page.",
            "shoppingCart.delivery": 'Delivery',
            "shoppingCart.TVA": "VAT included",
            "shoppingCart.finishOrder": "Finish order",
            "shoppingCart.resetCart": "Reset shopping cart",
            "shoppingCart.total": "Total",
            "size": 'Size',
            "quantity": 'Quantity',
            "login.title": "Welcome back!",
            "login.question1": "Do not have an account yet?",
            "login.createAccount": "Create account",
            "login.password": "Password",
            "login.passwordLabel": "Your Password",
            "login.rememberMe": "Remember Me",
            "login.forgotPassword": "Forgot Password?",
            "login.signIn": "Sign In",
            "login.resetPassword": "Reset password",
            "heroImg.title": "See the latest collection!",
            "heroImg.subTitle": "We are the first sports clothing brand from Romania that inspires you to surpass your limits every day! 🚀",
            "heroImg.shopMen": "Shop Men",
            "heroImg.shopWomen": "Shop Women",
            "heroImg2.title": "We want you to be unstoppable!",
            "heroImg2.description": "That's why our clothes are made from the highest quality materials, to support you on the path to victory!",
            "register.title": "Become a member!",
            "register.question1": "You're already a member?",
            "register.logIn": "Log in!",
            'register.createAcc': "Create account",
            "form.firstName": "First Name",
            "form.lastName": "Name",
            "form.phoneNumber": "Phone Number",
            "form.email": "Email",
            "form.password": "Password",
            "form.confirmPassword": "Confirm Password",
            "form.agreeTermsAndConditions": "I'm agree with <0>terms & conditions<0/> & <1>Confidentiality politicy<1/>.",
            "form.subject": "Subject",
            "form.message": "Your message",
            "form.submit": "Send",
            'myAccount.title': "Your account",
            'myAccount.finishedOrders': "Finished orders",
            'myAccount.memberFrom': "Member from",
            "myAccount.accountInformation": "Personal details",
            "myAccount.accountInformation.details": "Here you can see and edit your account details",
            "myAccount.myAddresses": "Addresses",
            "myAccount.myAddresses.details": "Add or edit your addresses here. <0/> If your address is outside Romania, please complete it on checkout page.",
            "myAccount.myAddresses.add": "Add address",
            "myAccount.myOrders": "Orders",
            "myAccount.myOrders.details": "Here you can see your orders",
            "myAccount.myOrders.order": "Order",
            "myAccount.myOrders.productsNr": "Nr of products: ",
            "myAccount.myOrders.orderMadeIn": "Processed in ",
            "myAccount.myOrders.total": "Total ",
            "myAccount.myOrders.return": "Return ",
            "myAccount.logout": "Logout",
            "addressCard.address": "Address",
            "addressCard.contactPerson": "Contact person",
            "addressCard.province": "Province",
            "addressCard.province.details": "Search and choose the province",
            "addressCard.city": "City",
            "addressCard.address1": "Street, number",
            "addressCard.address2": "Building, apartment",
            "addressCard.postalCode": "Postal code",
            "addressCard.phoneNumber": "Phone number",
            "addressCard.deleteQuestion": "Do you want to delete the address?",
            "addressCard.cancel": "Cancel",
            "addressCard.addAddress": "Add address",
            "addressCard.editAddress": "Edit address",
            "filtersAndSort.title": "Filters",
            'filter.byPrice': "Filter by price",
            "filter.btn.txt": "Apply filters",
            "filter.btn.reset": "Reset filters",
            "sort.sortTitle": "Sort products",
            "sort.placeholder": 'Sort by',
            "sort.bestsellers": "Bestsellers",
            "sort.newest": "Newest",
            "sort.biggerPrice": "Price: low to high",
            "sort.smallerPrice": "Price: high to low",
            "title.newestProducts": "Last drop",
            "title.menClothes": "Men clothes",
            "title.womenClothes": "Women clothes",
            "title.sales": "Sales",
            "products.noProductsByFilters": "We didn't find any products that match the filters.",
            "product.chooseSize": "Select size",
            "product.deliveryTime": "Delivery time",
            "product.deliveryTime.description": "Fast international shipping",
            "product.return": "Returns",
            "product.return.description": "Free return for 15 days",
            "product.addToBag": "Add to cart",
            "product.mightLike": "You might like",
            "sizeModal.men.title": "Size guide for men",
            "sizeModal.women.title": "Size guide for women",
            "sizeModal.title": "Size guide",
            "sizeModal.men.msg": "If you prefer a looser, oversize style, we recommend choosing the larger size.",
            "sizeModal.men.tshirt": "T-Shirt / Tank Top",
            "sizeModal.men.shorts": "Shorts",
            "sizeModal.women.bra": "Bra",
            "sizeModal.women.leggings": "Leggings",
            "sizeModal.size": "Size",
            "sizeModal.bust": "Chest (cm)",
            "sizeModal.underBust": "Under chest (cm)",
            "sizeModal.waist": "Waist (cm)",
            "sizeModal.insideLeg": "Inside leg (cm)",
            "sizeModal.guide": "Measurement guide",
            "contact.title": "Contact information",
            "contact.form.title": "Do you want to apply for the brand ambassador position or have other questions? <0/> Fill the above form and we will come back to you as soon as possible.",
            "footer.brandDescription": "We are the first sports clothing brand from Romania that inspires you to surpass your limits every day! 🚀",
            "footer.rightsReserved": "All rigths reserved",
            "footer.pages": "Pages",
            "footer.aboutUs": "About us",
            "footer.faq": "FAQ",
            "footer.contactUs": "Contact us",
            "footer.mobile.contactUs": "You need help? Contact us",
            "footer.articles": "Articles",
            "footer.account": "Account",
            "footer.login": "Login",
            "footer.register": "Register",
            "footer.privacy": "Privacy",
            "footer.termsAndConditions": "Terms & Conditions",
            "footer.confidentialityPolicy": "Confidentiality Policy",
            "footer.returnPolicy": "Return policy",
            "footer.deliveryInformation": "Delivery information",
            "notification.addToBag.title": "Product added to cart",
            "notification.addToBag.message": "was added to cart!",
            "notification.error.loginError": "Login error",
            "notification.error.loginError.description": "Your email or password is incorrect, try again!",
            "notification.error.checkoutError": "Something was wrong, please try again the checkout!",
            "notification.error": "Error",
            "notification.error.description": "Something was wrong, please try again!",
            "notification.success": "Action finished with success!",
            "notification.resetPassword.success": "You'll be receive an email with instructions about resetting your password.",
            "notification.freeDelivery.title": "Free shipping",
            "notification.freeDelivery.description": "Orders above <0>{{minPrice}}</0> have free shipping!",
            "notification.msgSent.title": "Message sent",
            "notification.msgSent.description": "Your message was sent! We will contact you on email!",
            "notification.msgNotSent.title": "Message not sent!",
            "notification.msgNotSent.description": "Something was wrong and your message wasn't sent! Please, try again!",
            "notification.returnProduct.description": "We will contact you soon for proceeding of return!",
            "notification.register.title": "Your account was registered!",
            "notification.register.description": "You'll receive a confirmation email, please follow the email steps.",
            "affix.scrollToTop": "Scroll to top",
            "validation.email": "Please enter a valid email address",
            "validation.password": "Please enter a stronger password, add minimum a big letter and a number",
            "validation.login.password": "Please enter a password",
            "validation.firstName": "The first name need to have minimum 3 characters",
            "validation.lastName": "The last name need to have minimum 3 characters",
            "validation.phoneNumber": "Please enter a valid phone number. Add the prefix before.",
            "validation.confirmPassword": "Password did not match",
            "validation.terms&conditions": "Please accept the terms & conditions",
            "validation.zipCode": "Please enter a valid zip code",
            "validation.city": "Please enter a valid city",
            "validation.province": "Please choose a province",
            "validation.address1": "Please enter the street and number",
            "validation.name": "The name field need to have minimum 3 characters",
            "validation.subject": "The subject need to have minimum 3 characters",
            "validation.message": "The message field need to have minimum 15 characters",
            "faq": "Frequently asked questions",
            "faq.question1": "What payment methods do you accept on slwactive?",
            "faq.response1": "We accept the following payment methods: credit/debit cards (Visa, MasterCard, American Express), cash on delivery and easybox payment.",
            "faq.question2": "What are the shipping costs and estimated delivery time?",
            "faq.response2": "Shipping costs and delivery times may vary depending on your location and the selected shipping options. Complete details are available after you'll finish the order.\nIn general, the delivery is done in 24h-48h.",
            "faq.question3": "Can I return or exchange the ordered products if I am not satisfied?",
            "faq.response3": "Yes, we accept returns and exchanges within 15 days of receiving your order. Please refer to our \"Shipping and Returns\" policy for more details on the return and exchange procedure.",
            "faq.question4": "How can I determine the right size for the sportswear I want to purchase?",
            "faq.response4": "To ensure you choose the correct size, we recommend checking our size guide available on each product page. It includes detailed information on measurements and how to select the right size.",
            "faq.question5": "What happens if the product I ordered has a quality issue?",
            "faq.response5": "We are committed to the quality of our products. If you encounter a quality issue, please contact us within 14 days of receiving your order. We will take measures to rectify the situation, either by replacing the product or refunding your money.",
            "faq.question6": "Do you offer discounts or special deals for customers?",
            "faq.response6": "Yes, we periodically offer special deals, discounts, and promotions for our customers. To stay updated on the latest offers, we recommend subscribing to our newsletter and following us on social media.",
            "aboutUs.p1": "We are ",
            "aboutUs.p2": ` We are the first brand that creates sporty, sport-casual clothing in Romania, with a focus on style and functionality. The design and quality of the clothes we create meet the highest fashion standards. of the moment.`,
            "aboutUs.p3": ` started from an observation of the creator, namely the need for a trendsetter in the world of brands that create sporty, sport-casual clothes for the Romanian market. <0/>
            The concept becoming a brand created and concluded in the first half of 2023.`,
            "aboutUs.p4": `For us SLWACTIVE means community, modern vision and continuous improvement. <0/> We are guided by the following values:`,
            "aboutUs.p5": `<0>S - Sweat</0> - We see sweat as a symbol of our evolution. <1/>
            Every drop signifies a step on the path to success and self-improvement.`,
            "aboutUs.p6": ` <0>L - Lift</0> - For us, Lift means much more than lifting weights. <1/>
            It's about lifting our goals and personal confidence in a process of personal development and commitment in sport and beyond.`,
            "aboutUs.p7": ` <0>W - Win </0> - In the end, it all comes down to a win, but it's much more than just prizes. 
            <br/>
             It's about pushing yourself beyond your limits, through discipline and an optimistic outlook, achieving success through effort and victory in personal development.`,
            "aboutUs.p8": ' is a start-up brand, managed and founded by Silviu Vlăduț.',
            "aboutUs.p9": 'Join our community',
            "cookies.title": "Your experience will be tailored with the help of cookies",
            "cookies.body": `<0>slwactive</0> uses cookies and other technologies to keep our sites reliable and secure, to measure their performance, to provide a personalized shopping experience, and personalized advertising. <1/> To do this, we collect information about users, their behavior, and their devices. <2/> If you select <3>“Accept cookies”</3>, you accept this and agree that we can share this information with third parties, such as our marketing partners. If you do not accept, we will use only essential cookies and, unfortunately, you will not receive any personalized content.`,
            "cookies.acceptAll": "Accept cookies",
            "cookies.acceptEssential": "Only essential",
            "cancel": "Cancel",
            "save": "Save",
            "delete": "Delete",
            "edit": "Edit",
            "notFound": "Not found",
            "easteregg.title": "Congrats! 🥳🥳",
            'easteregg.body': "You've unlocked a reward. <0/> Use code <1>traffic10</1> at checkout and enjoy a <1>10% discount</1> on your entire order."
        },
    },
    ro: {
        translation: {
            "announcement.msg": "Devino ambasadorul slwactive!",
            "announcement.msg2": "Livrare gratuită peste 199.99 RON & 15 zile retur gratuit!",
            "announcement.msg.delivery": "Livrare gratuită peste 199.99 RON!",
            "announcement.msg.return": "15 zile retur gratuit!",
            "announcement.link.register": "Creare cont",
            "announcement.link.login": "Intră în cont",
            "announcement.hello": "Salutare,",
            "header.btn.newest": "Noutăți",
            "header.btn.men": "Bărbați",
            "header.btn.women": "Femei",
            "header.btn.sales": "Reduceri",
            "header.btn.blog": "Blog",
            "blog.title": "Blogul slwactive",
            "category.men": "Bărbați",
            "category.women": "Femei",
            "btn.shopNow": "Cumpără acum",
            "badge.new": "Nou",
            "badge.sale": "Ofertă",
            "homepage.title": "Cele mai căutate produse",
            'homepage.title2': "Fii diferit",
            'homepage.title3': "Cele mai bune alegeri",
            "header.shoppingCart.title": "Coșul tău de cumpărături",
            "shoppingCart.emptyMsg": "Coșul tău este gol!",
            "shoppingCart.shopMen": "Vezi produse bărbați",
            "shoppingCart.shopWomen": "Vezi produse femei",
            "shoppingCart.redirectToCheckout": "In cateva momente vei fi redirectionat catre pagina de finalizare a comenzii.",
            "shoppingCart.delivery": "Transport",
            "shoppingCart.TVA": "TVA inclus",
            "shoppingCart.finishOrder": "Finalizează comanda",
            "shoppingCart.resetCart": "Golește coșul de cumpărături",
            "shoppingCart.total": "Total",
            "size": 'Mărime',
            "quantity": 'Cantitate',
            "login.title": "Bine ai revenit!",
            "login.question1": "Nu ai un cont încă?",
            "login.createAccount": "Crează cont",
            "login.password": "Parolă",
            "login.passwordLabel": "Parola ta",
            "login.rememberMe": "Ține-mă minte",
            "login.forgotPassword": "Ai uitat parola?",
            "login.signIn": "Conectează-te",
            "login.resetPassword": "Restare parolă",
            "heroImg.title": "Vezi colecția nouă!",
            "heroImg.subTitle": "Suntem primul brand de îmbrăcăminte sportivă din România care te inspiră să îți depășești limitele zilnic! 🚀",
            "heroImg.shopMen": "Produse bărbați",
            "heroImg.shopWomen": "Produse femei",
            "heroImg2.title": "Vrem să fii de neoprit!",
            "heroImg2.description": "De aceea, hainele noastre sunt făcute din cele mai calitative materiale, sa te susțină pe drumul victoriei!",
            "register.title": "Devino membru!",
            "register.question1": "Ai deja cont?",
            "register.logIn": "Conectează-te!",
            'register.createAcc': "Crează cont",
            "form.firstName": "Prenume",
            "form.lastName": "Nume",
            "form.phoneNumber": "Număr de telefon",
            "form.email": "Email",
            "form.password": "Parolă",
            "form.confirmPassword": "Confirmare parolă",
            "form.agreeTermsAndConditions": "Sunt de acord cu <0>termenii & condițile<0/> și <1>Politica de confidențialitate<1/>.",
            "form.subject": "Subiect",
            "form.message": "Mesaj",
            "form.submit": "Trimite",
            'myAccount.title': "Contul tău",
            'myAccount.finishedOrders': "Comenzi finalizate",
            'myAccount.memberFrom': "Membru din",
            "myAccount.accountInformation": "Detalii personale",
            "myAccount.accountInformation.details": "Aici poți vedea și modifica datele tale personale",
            "myAccount.myAddresses": "Adresele mele",
            "myAccount.myAddresses.details": "Adaugă sau modifică adresele tale aici. <0/> <1> Dacă adresa este din afara României te rugăm completeaz-o pe pagina de finalizare comandă. <1/>",
            "myAccount.myAddresses.add": "Adaugă adresă",
            "myAccount.myOrders": "Comenzile mele",
            "myAccount.myOrders.details": "Aici poți vedea comenzile tale",
            "myAccount.myOrders.order": "Comanda",
            "myAccount.myOrders.productsNr": "Număr produse: ",
            "myAccount.myOrders.orderMadeIn": "Procesată în ",
            "myAccount.myOrders.total": "Total ",
            "myAccount.myOrders.return": "Returnează ",
            "myAccount.logout": "Deconectează-te",
            "addressCard.address": "Adresă",
            "addressCard.contactPerson": "Persoană de contact",
            "addressCard.province": "Județ",
            "addressCard.province.details": "Caută și alege județul",
            "addressCard.city": "Oraș",
            "addressCard.address1": "Stradă. număr",
            "addressCard.address2": "Bloc, scară, apartament",
            "addressCard.postalCode": "Cod poștal",
            "addressCard.phoneNumber": "Număr de telefon",
            "addressCard.deleteQuestion": "Ești sigur că vrei să ștergi adresa?",
            "addressCard.cancel": "Anulează",
            "addressCard.addAddress": "Adaugă adresă",
            "addressCard.editAddress": "Editează adresă",
            "filtersAndSort.title": "Filtre",
            'filter.byPrice': "Filtrează după preț",
            "filter.btn.txt": "Aplică filtre",
            "filter.btn.reset": "Șterge filtre",
            "sort.sortTitle": "Sortează produsele",
            "sort.placeholder": 'Alege tipul sortării',
            "sort.bestsellers": "Bestsellers",
            "sort.newest": "Cele mai noi",
            "sort.biggerPrice": "Pret crescator",
            "sort.smallerPrice": "Pret descrescator",
            "title.newestProducts": "Ultimele produse lansate",
            "title.menClothes": "Haine bărbați",
            "title.womenClothes": "Haine femei",
            "title.sales": "Reduceri",
            "products.noProductsByFilters": "Nu am găsit produse care să corespundă filtrelor.",
            "product.chooseSize": "Alege mărimea",
            "product.deliveryTime": "Livrare",
            "product.deliveryTime.description": "Livrăm internațional",
            "product.return": "Retur",
            "product.return.description": "Retur gratuit 15 zile",
            "product.addToBag": "Adaugă în coș",
            "product.mightLike": "S-ar putea să-ți placă",
            "sizeModal.men.title": "Ghid de mărimi bărbați",
            "sizeModal.women.title": "Ghid de mărimi femei",
            "sizeModal.title": "Ghid de mărimi",
            "sizeModal.men.msg": "Dacă preferi un stil mai lejer, oversize, îți recomandăm sa alegi mărimea mai mare.",
            "sizeModal.men.tshirt": "Tricou / Maiou",
            "sizeModal.men.shorts": "Pantaloni scurtți",
            "sizeModal.women.bra": "Bustieră",
            "sizeModal.women.leggings": "Colanți",
            "sizeModal.size": "Mărime",
            "sizeModal.bust": "Piept (cm)",
            "sizeModal.underBust": "Sub piept (cm)",
            "sizeModal.waist": "Talie (cm)",
            "sizeModal.insideLeg": "Lungime picior (cm)",
            "sizeModal.guide": "Ghid de măsurare",
            "contact.title": "Metode de contact",
            "contact.form.title": "Vrei să aplici pentru poziția de brand ambasador sau ai alte întrebări? <0/> Completează formularul de mai jos și te vom contacta cât mai repede.",
            "footer.brandDescription": "Suntem primul brand de îmbrăcăminte sportivă din România care te inspiră să îți depășești limitele zilnic! 🚀",
            "footer.rightsReserved": "Toate drepturile sunt rezervate",
            "footer.pages": "Pagini",
            "footer.aboutUs": "Despre noi",
            "footer.faq": "FAQ",
            "footer.contactUs": "Contactează-ne",
            "footer.mobile.contactUs": "Ai nevoie de ajutor? Contactează-ne",
            "footer.articles": "Articole",
            "footer.account": "Cont",
            "footer.login": "Logare",
            "footer.register": "Înregistrare",
            "footer.privacy": "Confidențialitate",
            "footer.termsAndConditions": "Termeni și condiții",
            "footer.confidentialityPolicy": "Politica de confidențialitate",
            "footer.returnPolicy": "Retururi",
            "footer.deliveryInformation": "Transport",
            "notification.addToBag.title": "Produs adăugat în coș",
            "notification.addToBag.message": "a fost adăugat în coș!",
            "notification.error.loginError": "Eroare la autentificare",
            "notification.error.loginError.description": "Email sau parolă greșite, te rugăm reincearca!",
            "notification.error.checkoutError": "Ceva nu a funcționat corect, te rugăm reincearcă finalizarea comenzii!",
            "notification.error": "Eroare",
            "notification.error.description": "Ceva nu a funcționat corect, te rugăm reincearcă!",
            "notification.success": "Acțiune finalizată cu success!",
            "notification.resetPassword.success": "Vei primi un email cu instrucțiunile despre resetarea parolei!",
            "notification.freeDelivery.title": "Livrare gratuită",
            "notification.freeDelivery.description": "Comenzile peste <0>{{minPrice}}</0> beneficiază de livrare gratuită!",
            "notification.msgSent.title": "Mesajul trimis",
            "notification.msgSent.description": "Mesajul tău a fost trimis! Te vom contacta cât mai curând pe email. ",
            "notification.msgNotSent.title": "Mesajul nu a fost trimis!",
            "notification.msgNotSent.description": "A apărut o problemă, iar mesajul tău nu a fost trimis. Te rugăm reincearcă!",
            "notification.returnProduct.description": "Vă vom contacta în curând pentru procedura de returnare!",
            "notification.register.title": "Contul tău a fost înregistrat cu success!",
            "notification.register.description": "Vei primi un email de confirmare, urmărește instrucțiunile din email.",
            "affix.scrollToTop": "Du-mă sus",
            "validation.email": "Te rugăm să introduci o adresă de email validă",
            "validation.password": "Te rugăm să introduci o parolă mai puternică care să conțina minim o literă mare și o cifră",
            "validation.login.password": "Te rugăm să introduci o parolă",
            "validation.firstName": "Prenumele trebuie să aibă minim 3 caractere",
            "validation.lastName": "Numele de familie trebuie să aibă minim 3 caractere",
            "validation.phoneNumber": "Te rugăm să introduci un număr de telefon valid. Adauga prefixul inainte.",
            "validation.confirmPassword": "Parolele nu sunt aceleași",
            "validation.terms&conditions": "Te rugăm să accepți termenii & condițiile",
            "validation.zipCode": "Te rugăm să introduci un cod poștal valid",
            "validation.city": "Te rugăm să introduci un nume de oraș/sat valid",
            "validation.province": "Te rugăm să alegi un județul",
            "validation.address1": "Te rugăm să introduci strada și numărul",
            "validation.name": "Numele trebuie să aibă minim 3 caractere",
            "validation.subject": "Subiectul trebuie să aibă minim 3 caractere",
            "validation.message": "Mesajul trebuie să aibă minim 15 caractere",
            "faq": "Întrebări frecvente",
            "faq.question1": "Care sunt modalitățile de plată acceptate pe slwactive?",
            "faq.response1": "Acceptăm următoarele modalități de plată: card de credit/debit (Visa, MasterCard, American Express), plată ramburs la livrare și plată la easybox.",
            "faq.question2": "Care sunt costurile și timpul de livrare estimat?",
            "faq.response2": "Costurile și timpul de livrare pot varia în funcție de locația dvs. și opțiunile de expediere selectate. Detalii complete sunt disponibile după finalizarea comenzii.\nÎn general, livrarea se realizează in 24h-48h.",
            "faq.question3": "Pot returna sau schimba produsele comandate în caz că nu sunt mulțumit?",
            "faq.response3": "Da, acceptăm retururile și schimburile în termen de 15 de zile de la primirea comenzii. Vă rugăm să consultați politica noastră de \"Livrare și Retur\" pentru mai multe detalii cu privire la procedura de retur și schimb.",
            "faq.question4": "Cum pot afla mărimea potrivită pentru hainele sport pe care doresc să le cumpăr?",
            "faq.response4": " Pentru a vă asigura că alegeți mărimea potrivită, vă recomandăm să consultați ghidul nostru de mărimi disponibil pe fiecare pagină de produs. Acesta include informații detaliate despre măsurători și cum să alegeți mărimea potrivită.",
            "faq.question5": "Ce se întâmplă dacă produsul pe care l-am comandat nu este cum mi-am dorit?",
            "faq.response5": "Suntem dedicați clienților noștri. În cazul în care întâmpinați orice problemă cu produsul, vă rugăm să ne contactați în termen de maxim 15 zile de la primirea comenzii. Vom lua măsuri pentru a remedia situația, fie prin înlocuirea produsului, fie prin rambursarea banilor.",
            "faq.question6": "Aveți reduceri sau oferte speciale pentru clienți?",
            "faq.response6": "Da, periodic avem oferte speciale, reduceri și promoții pentru clienții noștri. Pentru a fi la curent cu cele mai recente oferte, vă recomandăm să vă abonați la newsletter-ul nostru și să ne urmăriți pe rețelele sociale.",
            "aboutUs.p1": "Noi suntem ",
            "aboutUs.p2": ` Suntem primul brand ce crează haine sport, sport-casual din România, cu accent pe stil și
            funcționalitate.
            Design-ul și calitatea hainelor create de noi îndeplinesc cele mai înalte standarde fashion
            din acest moment.`,
            "aboutUs.p3": `a pornit dintr-o observație a creatorului, mai exact nevoia de un trendsetter în
            lumea brandurilor ce crează haine sport, sport-casual pentru piața din România. <0/>
            Conceptul devenind un brand creat și concluzionat în prima jumătate a anului 2023.`,
            "aboutUs.p4": `Pentru noi SLWACTIVE înseamnă comunitate, viziune modernă și perfecționare
            continuă.<0/>Ne ghidăm după următoarele valori:`,
            "aboutUs.p5": `<0>S - Sweat</0> – Noi vedem transpirația ca un simbol al evoluției noastre. <1/>
            Fiecare picătură înseamnă un pas spre succes și autodepășire.`,
            "aboutUs.p6": ` <0>L - Lift</0> – Pentru noi, Lift înseamnă mult mai mult decât ridicarea
            greutăților. <1/>
            Este despre ridicarea scopurilor noastre și a încrederii personale într-un proces
            continuu de dezvoltare personală și angajament în sport și nu numai.`,
            "aboutUs.p7": ` <0>W - Win</0> – La final, totul se concluzionează într-o victorie, insă aceasta reprezintă
            mult mai mult decât premii. <br/> Este despre depășirea propriilor limite, prin
            disciplină și o perspectivă optimistă, realizând succes prin efort și victorie în
            dezvoltare personală.`,
            "aboutUs.p8": ' este un brand la început de drum, condus și fondat de Silviu Vlăduț.',
            "aboutUs.p9": 'Alătură-te comunității noastre',
            "cookies.title": "Experienta ta va fi adaptată cu ajutorul cookies",
            "cookies.body": `<0>slwactive</0> foloseste cookies și alte tehnologii pentru a menține site-urile noastre fiabile și sigure, pentru a le măsura performanța, pentru a oferi o experiență de cumpărături personalizată și publicitate personalizată. <1/>
            Pentru a face acest lucru, colectăm informații despre utilizatori, comportamentul lor și dispozitivele lor. <2/>
            Dacă selectați <3>„Accept cookies”</3> , acceptați acest lucru și sunteți de acord că putem partaja aceste informații cu terțe părți, cum ar fi partenerii noștri de marketing. Dacă nu acceptați, vom folosi doar cookies esențiale și, din păcate, nu vei primi niciun conținut personalizat. `,
            "cookies.acceptAll": "Accept cookies",
            "cookies.acceptEssential": "Doar esențial",
            "cancel": "Anulează",
            "save": "Salvează",
            "delete": "Șterge",
            "edit": "Editează",
            "notFound": "Nu există",
            "easteregg.title": "Felicitări! 🥳🥳",
            'easteregg.body': "Ai deblocat recompensa. <0/> Folosește codul <1>traffic10</1> la checkout și beneficiază de <1>10% reducere</1> la toată comanda."
        },
    },
};
