/* eslint-disable jsx-a11y/anchor-has-content */
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Button,
  createStyles,
  Flex,
  Image,
} from "@mantine/core";
import { FunctionComponent } from "react";
import {
  useCreateCustomer,
} from "../utils/api/customers/useCreateCustomer";
import { useForm } from "@mantine/form";
import Announcement from "../Announcement/Announcement";
import { useNavigate } from "react-router-dom";
import logo from "../utils/resources/logo2.svg";
import { Trans, useTranslation } from "react-i18next";
import {ICustomerCreateInput} from "../utils/api/types";
import {registerFormInitialValues, registerFormValidations} from "./utils";
import { IconCheck, IconX } from "@tabler/icons";
import { notifications } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  containerRoot: {
    background: theme.colors.gray[1],
  },
  notifPosition: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: "50%",
  },
}));

export const RegisterPage: FunctionComponent = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const registerForm = useForm({
    initialValues: registerFormInitialValues,
    validate: registerFormValidations(t)
  });
  const successCallBack = (data: any) => {
    if(data.customerCreate.customerUserErrors.length > 0) {
      notifications.show({ 
        icon: <IconX size='1.1rem' />,
        title: t('notification.error'),
        color: 'red',
        message: data.customerCreate.customerUserErrors[0].message
      })
    } else {
      notifications.show({
        icon: <IconCheck size='1.1rem' />,
        title: t('notification.register.title'),
        color: 'green',
        message: t('notification.register.description')
      })
      setTimeout(() => {
        navigate('/login')
      },1500)
    }

  };
  const { mutate, isLoading } = useCreateCustomer(
    successCallBack
  );
  const onSubmitForm = (values: any) => {
    const customerValues: ICustomerCreateInput = {
      input: {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        acceptsMarketing: values.acceptsMarketing,
      },
    };
    mutate(customerValues);
  };
  const navigate = useNavigate();
  const onAnchorClick = () => {
    navigate(`/login`);
  };

  return (
    <>
      <Container
        w="100vw"
        maw="100vw"
        h="100vh"
        mah="auto"
        px="0"
        className={classes.containerRoot}
      >
        <Announcement />
        <Flex justify={"center"}>
          {" "}
          <Image
            onClick={() => navigate("/")}
            height={100}
            width="auto"
            src={logo}
            alt="logo"
            sx={{ cursor: "pointer" }}
          />{" "}
        </Flex>
        <Container size={800} my='lg' pb='xl'>
          <Title align="center"> {t("register.title")} </Title>
          <Text color="gray.7" size="lg" align="center" mt={5}>
            {t("register.question1")}{" "}
            <Anchor
              size="lg"
              component="button"
              color="gray.9"
              type="button"
              onClick={() => onAnchorClick()}
            >
              {t("register.logIn")}
            </Anchor>
          </Text>
          <form
            onSubmit={registerForm.onSubmit((values) => onSubmitForm(values))}
          >
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
              <Flex direction="column" gap="md">
                <Flex
                  gap="xl"
                  justify={{
                    base: "center",
                  }}
                  direction={{
                    base: "column",
                    sm: "row",
                  }}
                >
                  <Flex gap="md" direction="column" w="100%">
                    <TextInput
                      label={t("form.firstName")}
                      placeholder="John"
                      {...registerForm.getInputProps("firstName")}
                    />
                    <TextInput
                      label={t("form.lastName")}
                      placeholder="Doe"
                      {...registerForm.getInputProps("lastName")}
                    />
                    <TextInput
                      label={t("form.phoneNumber")}
                      placeholder="+40745123123"
                      {...registerForm.getInputProps("phone")}
                    />
                  </Flex>
                  <Flex gap="md" direction="column" w="100%">
                    <TextInput
                      label={t("form.email")}
                      placeholder="you@slwactive.com"
                      {...registerForm.getInputProps("email")}
                    />
                    <PasswordInput
                      label={t("form.password")}
                      placeholder={t("form.password") as string}
                      {...registerForm.getInputProps("password")}
                    />
                    <PasswordInput
                      label={t("form.confirmPassword")}
                      placeholder={t("form.confirmPassword") as string}
                      {...registerForm.getInputProps("confirmPassword")}
                    />
                  </Flex>
                </Flex>
                <Flex
                  direction={{
                    base: "column",
                    sm: "row",
                  }}
                  justify="space-between"
                  align="center"
                > 
                  <Checkbox
                    label={
                      <Trans
                        i18nKey="form.agreeTermsAndConditions"
                        components={[
                        <a 
                        href='/termsAndConditions.pdf' 
                        target="_blank" 
                        style={{
                          color: 'black'
                        }}
                        />, 
                        <a 
                        href='/confidentialityPolicy.pdf' 
                        target="_blank" 
                        style={{
                          color: 'black'
                        }}
                        />
                      ]}
                      />
                    }
                    {...registerForm.getInputProps("acceptsMarketing")}
                  />
                  <Button
                    variant="filled"
                    radius="xl"
                    mt="xl"
                    type="submit"
                    loading={isLoading}
                  >
                    {t("register.createAcc")}
                  </Button>
                </Flex>
              </Flex>
            </Paper>
          </form>
        </Container>
      </Container>
    </>
  );
};
