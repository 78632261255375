import { Flex } from "@mantine/core";
import ImageButton from "../ImageButton/ImageButton";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { pushClickOnCategoryDataLayer } from "../utils/functions";

export const CategorySection: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCategoryClick = (route: string) => {
    navigate(`/${route}`);
  };
  return (
    <Flex justify="center" h="100%" direction={{ base: "column", sm: "row" }}>
      <ImageButton
        category={t("category.men")}
        btnMsg={t("btn.shopNow")}
        imgUrl='https://cdn.shopify.com/s/files/1/0770/7017/2509/files/man-slw.png?v=1702032221'
        onClick={() => {
          pushClickOnCategoryDataLayer('men');
          onCategoryClick("products?type=men")
        }}
      />
      <ImageButton
        category={t("category.women")}
        btnMsg={t("btn.shopNow")}
        imgUrl='https://cdn.shopify.com/s/files/1/0770/7017/2509/files/girl-slw.png?v=1702032220'
        onClick={() => {
          pushClickOnCategoryDataLayer('women');
          onCategoryClick("products?type=women")
        }}
      />
    </Flex>
  );
};
