import React, {FunctionComponent, useState} from "react";
import {
    ActionIcon,
    Flex, Grid,
    Image,
    Text,
    Button
} from "@mantine/core";
import {IconMinus, IconPlus} from "@tabler/icons";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {decreaseQuantityOfProduct, increaseQuantityOfProduct} from "../../utils/redux/ShoppingCart/shoppingCartSlice";

export interface IShoppingCartItemProps {
    image: string;
    title: string;
    size: string;
    quantity: number;
    price: string;
    currency: string;
    merchandiseId: string;
    prodUuid: string;
}

export const ShoppingCartItem: FunctionComponent<IShoppingCartItemProps> =
    ({
         image,
         title,
         size,
         quantity,
         price,
         currency,
         prodUuid
     }) => {
        const {t} = useTranslation ();
        const dispatch = useDispatch ();
        const [showDeleteMessage, setShowDeleteMessage] = useState ( false );

        const increaseQuantity = () => {
            dispatch ( increaseQuantityOfProduct ( {prodUuid: prodUuid} ) );
        }

        const decreaseQuantity = () => {
            if (quantity === 1) {
                setShowDeleteMessage ( true );
            } else {
                dispatch ( decreaseQuantityOfProduct ( {prodUuid: prodUuid} ) );
            }
        }

        const onPressDeleteYes = () => {
            dispatch ( decreaseQuantityOfProduct ( {prodUuid: prodUuid} ) );
            setShowDeleteMessage(false);
        }

        const onPressDeleteNo = () => {
            setShowDeleteMessage(false);
        }

        return (
            <Grid>
                <Grid.Col span={6}>
                    <Image
                        fit='contain'
                        src={image}
                        alt="productImg"
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <Flex direction="column" gap="sm" align={'center'}>
                        <Text align={'center'} fw={700} size="md" color="black">
                            {title}
                        </Text>
                        <Text align={'center'} fw={700} size="md" color="black">
                            {String ( (quantity * Number ( price )).toFixed ( 2 ) )} {currency}
                        </Text>
                        <Text align={'center'} fw={400} size="md" color="black">
                            {t ( 'size' )}: {size}
                        </Text>
                        {showDeleteMessage ?
                            <Flex direction='column' align='center'>
                                <Text align={'center'} fw={600} size="md" color="black">
                                    Doresti ca produsul sa fie sters din cos?
                                </Text>
                                <div>
                                    <Button
                                        variant='text'
                                        onClick={onPressDeleteYes}
                                    >
                                        Da
                                    </Button>
                                    <Button
                                        variant='text'
                                        onClick={onPressDeleteNo}
                                    >
                                        Nu
                                    </Button>
                                </div>
                            </Flex>
                            :
                            <Text align={'center'} fw={400} size="md" color="black">
                                {t ( 'quantity' )}
                                <Flex align='center' justify='center'>
                                    <ActionIcon size="lg" onClick={decreaseQuantity}>
                                        <IconMinus size="1.25rem"/>
                                    </ActionIcon>
                                    {quantity}
                                    <ActionIcon size="lg" onClick={increaseQuantity}>
                                        <IconPlus size="1.25rem"/>
                                    </ActionIcon>
                                </Flex>
                            </Text>}
                    </Flex>
                </Grid.Col>
            </Grid>
        );
    };
