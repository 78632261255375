import React, { FunctionComponent, useState } from "react";
import {
    createStyles,
    Text,
    Drawer,
    Flex,
    Title,
    Button,
    Loader,
    Alert
} from "@mantine/core";
import { IconAlertCircle, IconShoppingBag, IconX } from "@tabler/icons";
import { Trans, useTranslation } from "react-i18next";
import { ShoppingCartItem } from "../ShoppingCartItem/ShoppingCartItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/redux/store";
import { resetCart } from "../../utils/redux/ShoppingCart/shoppingCartSlice";
import { useCreateCart } from "../../utils/api/cart/useCreateCart";
import { ICartBody, ICartCreateResponse } from "../../utils/api/cart/cartTypes";
import { notifications } from "@mantine/notifications";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { pushClickOnCheckoutDataLayer } from "../../utils/functions";

export interface IShoppingCart {
    shoppingCartOpened: boolean;
    closeShoppingCart: () => void;
}

const useStyles = createStyles((theme) => ({
    title: {
        fontWeight: "bold",
        fontSize: theme.fontSizes.xl,
    },
    alertTitle: {
        color: theme.colors.brand[6]
    },
    priceContainer: {
        width: "100%",
        borderTop: `1px solid ${theme.colors.gray[5]}`, //@ts-ignore
        marginTop: theme.spacing.xl,
        position: "sticky",
        bottom: 0,
        background: theme.white
    },
    totalPrice: {
        "& > span": {
            color: theme.colors.gray[9],
            fontSize: theme.fontSizes.xs,
        },
    },
}));

export const ShoppingCart: FunctionComponent<IShoppingCart> = ({
    shoppingCartOpened,
    closeShoppingCart,
}) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { cartItems, totalPrice } = useSelector(
        (state: RootState) => state.shoppingCart,
    )
    const { customer } = useSelector(
        (state: RootState) => state.customer,
    )
    const bagIsEmpty: boolean = cartItems.length === 0;
    const dispatch = useDispatch()
    const [redirectForCheckout, setRedirectForCheckout] = useState(false);
    const navigate = useNavigate()
    const onCategoryClick = (route: string) => {
        navigate(`/${route}`);
    };
    const successCallback = (data: ICartCreateResponse) => {
        if (!data.cartCreate.userErrors.length) {
            window.location.replace(data.cartCreate.cart.checkoutUrl)
        } else {
            setRedirectForCheckout(false);
            closeShoppingCart();
            notifications.show({
                icon: <IconX size='1.1rem' />,
                title: t('notification.error.checkoutError'),
                color: 'red',
                message: data.cartCreate.userErrors[0].message
            })
        }
    }
    const { mutate, isLoading } = useCreateCart(successCallback)

    const onClickFinishOrder = () => {
        setRedirectForCheckout(true)
        const cartLines: any = [];
        let orderBody: ICartBody | any = {};
        cartItems.forEach(product => cartLines.push({
            quantity: product.quantity,
            merchandiseId: product.variantId
        }))
        if (window.localStorage.getItem('isLogged') === 'true') {
            orderBody = {
                input: {
                    lines: cartLines,
                    buyerIdentity: {
                        email: customer !== null ? customer.email : '',
                        customerAccessToken: localStorage.getItem('accessToken')
                    }
                }
            };
        } else {
            orderBody = {
                input: {
                    lines: cartLines,
                    buyerIdentity: {
                        countryCode: 'RO'
                    }
                }
            };
        }
        pushClickOnCheckoutDataLayer(totalPrice);
        mutate(orderBody)
    }

    return (
        <>
            <Drawer
                opened={shoppingCartOpened}
                onClose={closeShoppingCart}
                size="md"
                padding="xl"
                position="right"
                title={t("header.shoppingCart.title")}
                zIndex={1000000}
                classNames={{
                    title: classes.title
                }}
            >
                <Flex gap="0" justify="center" align="center" direction="column" mt="xl">
                    {isLoading || redirectForCheckout ? <>
                        <Text fw={700} size="lg" align='center'>
                            {t("shoppingCart.redirectToCheckout")}
                        </Text>
                        <Loader />
                    </> :
                        <>
                            {bagIsEmpty ? (
                                <Flex gap="0" justify="center" align="center" direction="column">
                                    <IconShoppingBag size={30} />
                                    <Title order={4}>{t("shoppingCart.emptyMsg")}</Title>
                                    <Button
                                        variant="filled"
                                        color="primary"
                                        radius="xl"
                                        fullWidth
                                        mt="xl"
                                        size='md'
                                        onClick={() => {
                                            closeShoppingCart();
                                            onCategoryClick("products?type=men")
                                        }}
                                    >
                                        {t("shoppingCart.shopMen")}
                                    </Button>
                                    <Button
                                        variant="filled"
                                        color="primary"
                                        radius="xl"
                                        mt="sm"
                                        fullWidth
                                        size='md'
                                        onClick={() => {
                                            closeShoppingCart();
                                            onCategoryClick("products?type=women")
                                        }}

                                    >
                                        {t("shoppingCart.shopWomen")}
                                    </Button>
                                </Flex>
                            ) : (
                                <Flex gap="xl" direction="column">
                                    {cartItems.map(item => <ShoppingCartItem
                                        key={_.uniqueId()}
                                        image={item.image} title={item.title} size={item.size}
                                        quantity={item.quantity} price={item.price}
                                        currency={item.currency} merchandiseId={item.merchandiseId}
                                        prodUuid={item.prodUuid}
                                    />)}
                                </Flex>
                            )}
                            {bagIsEmpty ? null : (
                                <Flex gap="0" pt='xs' direction="column" className={classes.priceContainer}>
                                    <Flex justify="space-between" align="center">
                                        <Text fw={700} size="lg" className={classes.totalPrice}>
                                            {t("shoppingCart.total")} <span>({t("shoppingCart.TVA")})</span>
                                        </Text>
                                        <Text fw={700} size="lg">
                                            {totalPrice} {cartItems[0].currency}
                                        </Text>
                                    </Flex>
                                    {totalPrice < 199.99 ?
                                        <Alert icon={<IconAlertCircle size="1rem" />} title={t('notification.freeDelivery.title')} color="indigo" mt="xl"
                                            classNames={{
                                                title: classes.alertTitle,
                                                icon: classes.alertTitle
                                            }}>
                                            <Trans
                                                i18nKey="notification.freeDelivery.description"
                                                values={{
                                                    minPrice: '199.99 RON',
                                                }}
                                                components={[<b />]}
                                            />
                                        </Alert> : null}
                                    <Button
                                        variant="filled"
                                        color="primary"
                                        radius="xl"
                                        mt='xl'
                                        mb='xs'
                                        fullWidth
                                        size='md'
                                        onClick={onClickFinishOrder}>
                                        {t("shoppingCart.finishOrder")}
                                    </Button>
                                    <Button
                                        variant="subtle"
                                        color="dark.7"
                                        radius="xl"
                                        mb='xl'
                                        fullWidth
                                        size='md'
                                        onClick={() => {
                                            dispatch(resetCart({}))
                                        }}
                                    >
                                        {t("shoppingCart.resetCart")}
                                    </Button>
                                </Flex>
                            )}
                        </>
                    }
                </Flex>
            </Drawer>
        </>
    );
}
    ;
