import React, { FunctionComponent } from "react";
import {Card, Text, createStyles, Button, Flex} from "@mantine/core";

export interface ImageButtonProps {
  imgUrl: string;
  category: string;
  btnMsg: string;
  onClick: () => void
}

const ImageButton: FunctionComponent<ImageButtonProps> = ({
  imgUrl,
  category,
  btnMsg,
  onClick,
}) => {  
  const useStyles = createStyles((theme) => ({
    card: {
      backgroundImage: `url('${imgUrl }')`,
      height: "750px",
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      cursor: "pointer",
      borderRadius: '0',

      [theme.fn.smallerThan ( 'sm' )]: {
        height: '50vh'
    },

      
    },
    category: {
      color: theme.white,
      fontWeight: 700,
      textTransform: "uppercase",
      textAlign: 'right'
    },
    button: {
      background: theme.white,
      color: theme.colors.gray[8],
      "&:hover": {
        color: theme.white,
      },
    },
  }));

  const { classes } = useStyles();

  return (
    <Card className={classes.card} onClick={onClick}>
      <Text className={classes.category} size="md">
        {category}
      </Text>
      <Flex h='100%' w='100%' justify='center' align='flex-end'>
      <Button
        variant="filled"
        color="primary"
        radius="xl"
        onClick={onClick}
        mb='xl'
      >
        {btnMsg}
      </Button>
      </Flex>
    </Card>
  );
};

export default ImageButton;


